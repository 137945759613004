import React, { useCallback, useEffect, useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

import { DialogModal, Loader, MenuItem, ModalBox, Table } from 'components';

import { PaymentCreateEditForm } from './PaymentCreateEditForm';
import { paymentColumns } from './paymentColumns';

import { useParams, useSelector, useTranslation } from 'third-party';

import { usePayment } from 'modules/payment';

import { paymentSelectors } from 'selectors';

import { APIPayment, APIPaymentStatus, APISortOrder } from 'types/api';

export const PaymentList = () => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState(paymentColumns);
  const [selectedItem, setSelectedItem] = useState<APIPayment | null>(null);
  const { organizationId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    loadPayments,
    loadMorePayments,
    updatePaymentFilters,
    deletePayment,
    clearPaymentFilters,
    downloadPayment,
  } = usePayment();

  const items = useSelector(paymentSelectors.paymentList);
  const paymentsListLoaded = useSelector(paymentSelectors.paymentsListLoaded);
  const allPaymentsLoaded = useSelector(paymentSelectors.allPaymentsLoaded);
  const paymentFilters = useSelector(paymentSelectors.paymentFilters);
  const loadPaymentsPending = useSelector(paymentSelectors.loadPaymentsPending);
  const loadMorePaymentsPending = useSelector(
    paymentSelectors.loadMorePaymentsPending,
  );

  useEffect(() => {
    return () => {
      clearPaymentFilters();
    };
  }, [clearPaymentFilters, organizationId]);

  useEffect(() => {
    loadPayments();
  }, [paymentFilters, loadPayments]);

  const onColumnFilterChange = useCallback(
    (columnId: string, value: string) => {
      setColumns(
        columns.map(column => {
          if (column.dataId === columnId) {
            return { ...column, filter: value };
          }
          return column;
        }),
      );

      updatePaymentFilters({ ...paymentFilters, [columnId]: value });
    },
    [paymentFilters, columns, updatePaymentFilters],
  );

  const onFilterReset = useCallback(() => {
    updatePaymentFilters({});
    setColumns(it => it.map(column => ({ ...column, filter: undefined })));
  }, [updatePaymentFilters]);

  const onColumnVisibilityChanged = useCallback(
    (columnId: string, value: boolean) => {
      setColumns(it =>
        it.map(column => {
          if (column.dataId === columnId) {
            return { ...column, hidden: !value };
          }
          return column;
        }),
      );
    },
    [],
  );

  const rowOptionsMenu = useCallback(
    (item: APIPayment, onRowMenuClose: () => void): React.ReactElement[] => [
      <MenuItem
        key={'delete'}
        disabled={item.status === APIPaymentStatus.Void}
        onClick={() => {
          setSelectedItem(item);
          onRowMenuClose();
        }}
      >
        <DeleteOutlineOutlinedIcon
          color="primary"
          style={{ paddingRight: '0.5rem' }}
        />
        {t('table.delete')}
      </MenuItem>,
      <MenuItem
        key={'edit'}
        onClick={() => {
          setSelectedItem(item);
          setIsModalOpen(true);
          onRowMenuClose();
        }}
      >
        <EditOutlinedIcon color="primary" style={{ paddingRight: '0.5rem' }} />
        {t('table.edit')}
      </MenuItem>,
      <MenuItem
        key={'download'}
        onClick={async () => {
          const link = await downloadPayment(item.id);
          window.open(link.payload, '_blank');
        }}
      >
        <GetAppOutlinedIcon
          color="primary"
          style={{ paddingRight: '0.5rem' }}
        />
        {t('table.download')}
      </MenuItem>,
    ],
    [downloadPayment, t],
  );

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedItem(null);
  }, []);

  const onColumnHeaderClicked = useCallback(
    (columnDataId: string) => {
      const existingSortOrder = paymentFilters.searchSortOrders?.find(
        order => order.sortField === columnDataId,
      );

      const newSortOrder =
        existingSortOrder && existingSortOrder.sortOrder === APISortOrder.DESC
          ? APISortOrder.ASC
          : APISortOrder.DESC;

      updatePaymentFilters({
        ...paymentFilters,
        searchSortOrders: [
          {
            sortField: columnDataId,
            sortOrder: newSortOrder,
          },
        ],
      });
    },
    [paymentFilters, updatePaymentFilters],
  );

  if (!paymentsListLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Table<APIPayment>
        header={t('paymentList.header')}
        subHeader={t('paymentList.subHeader')}
        filterable
        allItemsLoaded={allPaymentsLoaded}
        infiniteScroll
        tableMinHeight="20rem"
        columns={columns}
        items={items || []}
        sortOrder={paymentFilters.searchSortOrders?.[0]?.sortOrder}
        rowOptionsMenu={rowOptionsMenu}
        onColumnHeaderClicked={onColumnHeaderClicked}
        onColumnFilterChange={onColumnFilterChange}
        onColumnVisibilityChanged={onColumnVisibilityChanged}
        loadItemsPending={loadPaymentsPending}
        loadMoreItemsPending={loadMorePaymentsPending}
        onLoadMoreClicked={loadMorePayments}
        onFilterReset={onFilterReset}
        bodyRowHeight="1rem"
        headerRowHeight="0.2rem"
        itemsLoaded={paymentsListLoaded}
        onNewItemButtonClicked={
          organizationId ? () => setIsModalOpen(true) : undefined
        }
        emptyText={t('table.empty')}
        loadMoreButtonText={t('table.loadMore')}
      />
      {isModalOpen && (
        <ModalBox width="33rem" height="39rem" onClose={onModalClose}>
          <PaymentCreateEditForm
            onModalClose={onModalClose}
            paymentId={selectedItem?.id}
          />
        </ModalBox>
      )}
      <DialogModal
        onClose={() => setSelectedItem(null)}
        open={!!selectedItem && !isModalOpen}
        description={t('payeeList.deleteConfirm')}
        handleActionClick={() => {
          if (selectedItem) {
            deletePayment(selectedItem.id);
          }
          setSelectedItem(null);
        }}
      />
    </>
  );
};
