import { TableColumn } from 'components/Table/types';

import { APIPayee } from 'types/api';

export const payeeColumns: TableColumn<APIPayee>[] = [
  {
    dataId: 'id',
    label: 'payeeList.column.id',
    minWidth: 80,
    width: 140,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'nameOnCheck',
    label: 'payeeList.column.nameOnCheck',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'email',
    label: 'payeeList.column.email',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'phone',
    label: 'payeeList.column.phone',
    minWidth: 80,
    width: 100,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'address',
    label: 'payeeList.column.address',
    minWidth: 80,
    width: 170,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'city',
    label: 'payeeList.column.city',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'state',
    label: 'payeeList.column.state',
    minWidth: 80,
    width: 80,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'zip',
    label: 'payeeList.column.zip',
    minWidth: 80,
    width: 100,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'user.firstName',
    label: 'payeeList.column.user.firstName',
    minWidth: 80,
    width: 100,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'user.lastName',
    label: 'payeeList.column.user.lastName',
    minWidth: 80,
    width: 100,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'user.email',
    label: 'payeeList.column.user.email',
    minWidth: 80,
    width: 100,
    hidden: true,
    sortable: true,
    resizable: true,
  },
];
