import React, { useState } from 'react';

import styled from 'styled-components';

import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from '@mui/material';
import { FormField, TextField } from 'components';

import { dayjs, useForm } from 'third-party';

import { DISPLAY_DATE_FORMAT } from 'constants/common';

import { FormFieldProps } from 'components/FormField/FormField';
import { DesktopDatePicker } from 'components/index';

const StyledDatepicker = styled(DesktopDatePicker)`
  width: 100%;
`;

const popperDateSx = {
  '& .MuiPickersCalendarHeader-label': {
    fontSize: '1.6rem',
    textTransform: 'uppercase',
    fontWeight: 900,
  },
  '& .PrivatePickersYear-yearButton': {
    fontSize: '1.6rem',
    margin: '0.4rem 0',
    '&.Mui-selected': {
      color: '#ffffff',
    },
  },
  '& .MuiPickersDay-dayWithMargin': {
    fontSize: '1.6rem',
    color: '#5C6C80',
    '&[aria-selected="true"]': {
      color: '#ffffff',
    },
    '&[aria-current="date"]': {
      borderColor: '#AFD8D4',
      backgroundColor: '#AFD8D4',
    },
  },
};

type DateFieldProps = FormFieldProps;

export const FormDateField: React.FC<DateFieldProps> = ({ ...props }) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const {
    formState: { errors },
  } = useForm();

  return (
    <FormField
      render={({ field }) => (
        <StyledDatepicker
          open={datePickerOpen}
          onOpen={() => setDatePickerOpen(true)}
          onClose={() => setDatePickerOpen(false)}
          format={DISPLAY_DATE_FORMAT}
          value={dayjs(field.value)}
          disabled={props.isDisabled}
          onChange={field.onChange}
          // @ts-ignore
          PopperProps={{
            sx: popperDateSx,
            placement: 'bottom-start',
          }}
          renderInput={(
            params: React.JSX.IntrinsicAttributes & {
              variant?: TextFieldVariants | undefined;
            } & Omit<
                | FilledTextFieldProps
                | OutlinedTextFieldProps
                | StandardTextFieldProps,
                'variant'
              >,
          ) => (
            <TextField
              size="small"
              variant="outlined"
              InputProps={{ style: { fontSize: 40, color: 'red' } }}
              {...params}
              disabled={props.isDisabled}
              error={!!errors[props.fieldName] && props.showError}
              onClick={() => setDatePickerOpen(true)}
            />
          )}
        />
      )}
      {...props}
    />
  );
};
