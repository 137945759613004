import React, { useEffect } from 'react';

import { Box, Button, FormField, Loader, PageHeader } from 'components';
import { useNotifications } from 'hooks';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useOrganization } from 'modules/organization';

import { organizationSelectors } from 'selectors';

import { APIOrganization } from 'types/api';

enum FormFields {
  Name = 'name',
  description = 'description',
}

type FormData = {
  [FormFields.Name]: string;
  [FormFields.description]: string;
};

type CreateEditProps = {
  onModalClose: () => void;
  organizationId?: string | null;
};

export const OrganizationCreateEditForm: React.FC<CreateEditProps> = ({
  onModalClose,
  organizationId,
}) => {
  const { loadOrganization, clearOrganization } = useOrganization();
  const organization = useSelector(organizationSelectors.organization);
  const isEditMode = !!organizationId;

  useEffect(() => {
    if (organizationId) {
      loadOrganization(organizationId);
    }
    return () => {
      clearOrganization();
    };
  }, [organizationId, loadOrganization, clearOrganization]);

  if (isEditMode && !organization) {
    return <Loader />;
  }

  return (
    <CreateEditForm
      organization={organization}
      isEditMode={isEditMode}
      onModalClose={onModalClose}
    />
  );
};

type Props = {
  organization: APIOrganization | null;
  onModalClose: () => void;
  isEditMode: boolean;
};

export const CreateEditForm: React.FC<Props> = ({
  organization,
  isEditMode,
  onModalClose,
}) => {
  const { error, success } = useNotifications();
  const { updateOrganization, createOrganization } = useOrganization();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Name]: organization?.name || '',
      [FormFields.description]: organization?.description || '',
    },
  });

  const isFormPending = false;

  const handleCancel = () => {
    onModalClose();
  };

  const onSubmit = async (formData: FormData) => {
    if (isEditMode && organization) {
      const result = await updateOrganization(
        organization?.id,
        formData as APIOrganization,
      );
      if (result.error) {
        error(result.payload.body?.title || t('organizationForm.edit.error'));
      } else {
        success(t('organizationForm.edit.sucscefull'));
        onModalClose();
      }
      return;
    }

    // create
    const result = await createOrganization(formData as APIOrganization);
    if (result.error) {
      error(result.payload.body?.title || t('organizationForm.create.error'));
    } else {
      success(t('organizationForm.create.sucscefull'));
      onModalClose();
    }
  };

  return (
    <Box padding="1.5rem">
      <form>
        <PageHeader
          header={
            isEditMode
              ? t('organizationForm.edit.header')
              : t('organizationForm.create.header')
          }
          subHeader={
            isEditMode
              ? t('organizationForm.edit.subHeader')
              : t('organizationForm.create.subHeader')
          }
        >
          <Box display="flex" columnGap="1rem">
            <Button
              type="submit"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
              size="medium"
              color="primary"
            >
              {t('common.form.save')}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={handleCancel}
              size="medium"
              color="error"
            >
              {t('common.form.cancel')}
            </Button>
          </Box>
        </PageHeader>
        <FormField
          fieldName={FormFields.Name}
          fieldError={errors[FormFields.Name] as FieldError}
          label={t('organizationList.column.name')}
          placeholder={
            t('common.form.enterValueFor') + t('organizationList.column.name')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={150}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.description}
          fieldError={errors[FormFields.description] as FieldError}
          label={t('organizationList.column.description')}
          placeholder={
            t('common.form.enterValueFor') +
            t('organizationList.column.description')
          }
          control={control}
          maxLength={250}
          isRequired={false}
          isDisabled={isFormPending}
        />
      </form>
    </Box>
  );
};
