import { useEffect } from 'react';

import { useParams, useSelector } from 'third-party';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

export const useOrganization = () => {
  const { organizationId } = useParams();
  const userId = useSelector(appSelectors.userId);
  const { selectOrganization } = useApp();

  useEffect(() => {
    if (userId) {
      selectOrganization(organizationId);
    }
  }, [organizationId, userId, selectOrganization]);
};
