import React, { useEffect, useState } from 'react';

import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from 'components';
import { useNotifications } from 'hooks';

import {
  styled,
  useForm,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { dashboardUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { FormField } from 'components/FormField/FormField';

import { emailField } from 'utils/validations';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  min-width: 25rem;
`;

enum FormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

const loginFormDefaultValues = {
  [FormFields.FirstName]: '',
  [FormFields.LastName]: '',
  [FormFields.Email]: '',
  [FormFields.Password]: '',
  [FormFields.ConfirmPassword]: '',
};

type FormData = typeof loginFormDefaultValues;

export const UserRegister = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<typeof loginFormDefaultValues>({
    defaultValues: loginFormDefaultValues,
  });

  const watchedPasswordField = watch(FormFields.Password);

  const navigate = useNavigate();
  const { userRegister } = useApp();
  const { error, success } = useNotifications();
  const userInfoPending = useSelector(appSelectors.userInfoPending);
  const user = useSelector(appSelectors.user);

  useEffect(() => {
    if (!userInfoPending && user) {
      navigate(dashboardUrl());
    }
  }, [navigate, user, userInfoPending]);

  const onSubmit = async (formData: FormData) => {
    const { confirmPassword, ...registrationData } = formData;
    const result = await userRegister(registrationData);

    if (result.error) {
      error(result.payload.body?.title || t('register.genericError'));
      return;
    }

    success(t('register.success'));
    navigate(dashboardUrl());
  };

  return (
    <Box>
      <Box paddingBottom="1rem">
        <Typography variant="h2" textAlign="center">
          {t('register.header')}
        </Typography>
        <Typography variant="subtitle1" paddingTop="0.8rem">
          {t('register.subHeader')}
        </Typography>
      </Box>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          fieldName={FormFields.FirstName}
          label={t('register.fields.firstName')}
          fieldError={errors[FormFields.FirstName]}
          placeholder={
            t('common.form.enterValueFor') + t('register.fields.firstName')
          }
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
        />
        <FormField
          fieldName={FormFields.LastName}
          label={t('register.fields.lastName')}
          fieldError={errors[FormFields.LastName]}
          placeholder={
            t('common.form.enterValueFor') + t('register.fields.lastName')
          }
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
        />
        <FormField
          fieldName={FormFields.Email}
          label={t('register.fields.email')}
          fieldError={errors[FormFields.Email]}
          placeholder={
            t('common.form.enterValueFor') + t('register.fields.email')
          }
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
          rules={{
            validate: {
              invalidEmail: (value: string) =>
                emailField.validate(value) ||
                t('common.form.email.error.invalid'),
            },
          }}
        />
        <FormField
          type={showPassword ? 'text' : 'password'}
          fieldName={FormFields.Password}
          fieldError={errors[FormFields.Password]}
          label={t('register.fields.password')}
          placeholder={
            t('common.form.enterValueFor') + t('register.fields.password')
          }
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormField
          type={showPassword ? 'text' : 'password'}
          fieldName={FormFields.ConfirmPassword}
          fieldError={errors[FormFields.ConfirmPassword]}
          label={t('register.fields.confirmPassword')}
          placeholder={
            t('common.form.enterValueFor') +
            t('register.fields.confirmPassword')
          }
          // showError={touchedFields[FormFields.ConfirmPassword] === true}
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
          rules={{
            validate: {
              matchPasswordField: (value: string) =>
                !watchedPasswordField ||
                value === watchedPasswordField ||
                t('register.error.passwordNotSame'),
            },
            deps: [FormFields.Password],
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          paddingTop="1rem"
          gap="1.7rem"
        >
          <Button
            type="submit"
            variant="outlined"
            size="medium"
            color="primary"
          >
            <PersonOutlineRoundedIcon />
            {t('login.register')}
          </Button>
          <Typography variant="subtitle1" textAlign="center" maxWidth="25rem">
            {t('register.terms')}
          </Typography>
        </Box>
      </Form>
    </Box>
  );
};
