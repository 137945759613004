import { State } from 'modules';

export const loadUserPending = (state: State) => state.user.loadUserPending;
export const loadBankPending = (state: State) => state.user.loadUserPending;

export const user = (state: State) => state.user.user;

export const loadMoreUsersPending = (state: State) =>
  state.user.loadUserPending;

export const allUsersLoaded = (state: State) => state.user.allUsersLoaded;
export const userList = (state: State) => state.user.usersList;
export const userFilters = (state: State) => state.user.filters;
export const usersListLoaded = (state: State) => state.user.usersListLoaded;
