import {
  OrganizationAdminMenu,
  OrganizationUserMenu,
} from './OrganizationMenu';
import { UserMenu } from './UserMenu';

import { useSelector } from 'third-party';

import { appSelectors } from 'selectors';

import { APIUserType } from 'types/api';

export const useMenu = () => {
  const selected = useSelector(appSelectors.selectedOrganization);
  const user = useSelector(appSelectors.user);

  if (!selected) {
    return UserMenu();
  }

  if (
    (selected && selected.userType === APIUserType.Admin) ||
    user?.userType == APIUserType.Admin
  ) {
    return [
      ...OrganizationUserMenu(selected.organization.id),
      ...OrganizationAdminMenu(selected.organization.id),
    ];
  }

  return OrganizationUserMenu(selected.organization.id);
};
