import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import {
  AdapterDayjs,
  IconButton,
  Loader,
  LocalizationProvider,
} from 'components';
import { useAuth, useOrganization } from 'hooks';

import {
  RouterOutlet,
  SnackbarKey,
  SnackbarProvider,
  createGlobalStyle,
  cssResetStyles,
  useSelector,
  useSnackbar,
} from 'third-party';

import { appSelectors } from 'selectors';

const GlobalStyle = createGlobalStyle`
  ${cssResetStyles}
 

  html {
    font-family: 'Inter';
  }

  #root {
    display: flex;
    height: 100%;
  }
`;

const SnackbarCloseButton = ({ snackbarKey }: { snackbarKey: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon style={{ fill: 'white' }} />
    </IconButton>
  );
};

export const MainContainer = () => {
  return (
    <>
      <GlobalStyle />
      <RouterOutlet />
    </>
  );
};

export const AppContainer = () => {
  const isAppLoaded = useSelector(appSelectors.isAppLoaded);

  useOrganization();
  useAuth();

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {!isAppLoaded ? <Loader /> : <RouterOutlet />}
      </LocalizationProvider>
    </SnackbarProvider>
  );
};
