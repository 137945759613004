import React, { useEffect } from 'react';

import {
  Box,
  Button,
  FormField,
  Loader,
  MenuItem,
  PageHeader,
  Typography,
} from 'components';
import { useNotifications } from 'hooks';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useUser } from 'modules/user';

import { userSelectors } from 'selectors';

import { APIAccessLevel, APIUser, APIUserType } from 'types/api';

enum FormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  password = 'password',
  userType = 'userType',
  accessLevel = 'accessLevel',
}

type FormData = {
  [FormFields.firstName]: string;
  [FormFields.lastName]: string;
  [FormFields.email]: string;
  [FormFields.userType]: APIUserType;
  [FormFields.accessLevel]: APIAccessLevel;
};

type CreateEditProps = {
  onModalClose: () => void;
  userId?: string | null;
};

export const UserCreateEditForm: React.FC<CreateEditProps> = ({
  userId,
  onModalClose,
}) => {
  const { loadUser, clearUser } = useUser();

  const isEditMode = !!userId;

  const user = useSelector(userSelectors.user);

  useEffect(() => {
    if (userId) {
      loadUser(userId);
    }
    return () => {
      clearUser();
    };
  }, [userId, loadUser, clearUser]);

  if (isEditMode && !user) {
    return <Loader />;
  }

  return (
    <CreateEditForm
      user={user}
      onModalClose={onModalClose}
      isEditMode={isEditMode}
    />
  );
};

type Props = {
  user: APIUser | null;
  isEditMode: boolean;
  onModalClose: () => void;
};

const selectOptions = (enumObject: Record<string, unknown>) => {
  return Object.keys(enumObject).map((option: string) => (
    <MenuItem key={option} value={option}>
      <Typography variant="body2">{option}</Typography>
    </MenuItem>
  ));
};

export const CreateEditForm: React.FC<Props> = ({
  user,
  isEditMode,
  onModalClose,
}) => {
  const { error, success } = useNotifications();
  const { updateUser, createUser } = useUser();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.firstName]: user?.firstName || '',
      [FormFields.lastName]: user?.lastName || '',
      [FormFields.email]: user?.email || '',
      [FormFields.userType]: user?.userType || APIUserType.User,
      [FormFields.accessLevel]: user?.accessLevel || APIAccessLevel.User,
    },
  });

  const isFormPending = false;

  const handleCancel = () => {
    onModalClose();
  };

  const onSubmit = async (formData: FormData) => {
    if (isEditMode && user) {
      const result = await updateUser(user?.id, formData as APIUser);
      if (result.error) {
        error(result.payload.body?.title || t('userForm.edit.error'));
      } else {
        success(t('userForm.edit.sucscefull'));
        onModalClose();
      }
      return;
    }

    // create
    const result = await createUser(formData as APIUser);
    if (result.error) {
      error(result.payload.body?.title || t('userForm.create.error'));
    } else {
      success(t('userForm.create.sucscefull'));
      onModalClose();
    }
  };

  return (
    <Box padding="1.5rem">
      <form>
        <PageHeader
          header={
            isEditMode ? t('userForm.edit.header') : t('userForm.create.header')
          }
          subHeader={
            isEditMode
              ? t('userForm.edit.subHeader')
              : t('userForm.create.subHeader')
          }
        >
          <Box display="flex" columnGap="1rem">
            <Button
              type="submit"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
              size="medium"
              color="primary"
            >
              {t('common.form.save')}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={handleCancel}
              size="medium"
              color="error"
            >
              {t('common.form.cancel')}
            </Button>
          </Box>
        </PageHeader>
        <FormField
          fieldName={FormFields.firstName}
          fieldError={errors[FormFields.firstName] as FieldError}
          label={t('userList.column.firstName')}
          placeholder={
            t('common.form.enterValueFor') + t('userList.column.firstName')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={200}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.lastName}
          fieldError={errors[FormFields.lastName] as FieldError}
          label={t('userList.column.lastName')}
          placeholder={
            t('common.form.enterValueFor') + t('userList.column.lastName')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={200}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.email}
          fieldError={errors[FormFields.email] as FieldError}
          label={t('userList.column.email')}
          placeholder={
            t('common.form.enterValueFor') + t('userList.column.email')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={200}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.userType}
          fieldError={errors[FormFields.userType] as FieldError}
          label={t('userList.column.userType')}
          placeholder={
            t('common.form.enterValueFor') + t('userList.column.userType')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={200}
          selectOptions={selectOptions(APIUserType)}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.accessLevel}
          fieldError={errors[FormFields.accessLevel] as FieldError}
          label={t('userList.column.accessLevel')}
          placeholder={
            t('common.form.enterValueFor') + t('userList.column.accessLevel')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={200}
          selectOptions={selectOptions(APIAccessLevel)}
          isRequired
          isDisabled={isFormPending}
        />
      </form>
    </Box>
  );
};
