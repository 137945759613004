import { useEffect } from 'react';

import { useNavigate, useParams, useSelector } from 'third-party';

import { loginUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { isUserAuthenticated } from 'utils/auth';

export const useAuth = () => {
  const isUserLogged = isUserAuthenticated();
  const { organizationId } = useParams();
  const isAppLoaded = useSelector(appSelectors.isAppLoaded);
  const selectedOrganizationId = useSelector(appSelectors.organizationId);
  const userId = useSelector(appSelectors.userId);

  const navigate = useNavigate();

  const { userInfo, setAppIsLoaded } = useApp();

  useEffect(() => {
    if (isAppLoaded) {
      return;
    }

    if (!isUserLogged) {
      setAppIsLoaded(true);
      navigate(loginUrl());
      return;
    }

    if (!userId) {
      userInfo().then(it => {
        if (it.error) {
          navigate(loginUrl());
        }
      });
    }

    if (organizationId && organizationId != selectedOrganizationId) {
      return;
    }

    setAppIsLoaded(true);
  }, [
    isAppLoaded,
    userId,
    isUserLogged,
    navigate,
    organizationId,
    selectedOrganizationId,
    setAppIsLoaded,
    userInfo,
  ]);
};
