import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: {
      'common.delete': 'Delete',
      'common.apply': 'Apply',
      'common.reset': 'Reset',
      'common.approve': 'Approve',
      'common.loading': 'Loading ...',
      'common.loadMore': 'Load more',
      'common.send': 'Send',
      'common.slogan': 'Easy electronic check management',
      'common.logout': 'Log out',

      // empty
      'common.empty.header': 'This page is under development',
      'common.empty.subHeader': 'This is empty sub header',

      // forms
      'common.form.email.label': 'email',
      'common.form.email.placeholder': 'Enter email address',
      'common.form.email.error.invalid': 'Invalid Email Address',
      'common.form.email.notFoundText': 'No email found',
      'common.form.emptyFieldError': 'This field is required',

      'common.form.password.label': 'Password',
      'common.form.password.placeholder': 'Enter Password',
      'common.form.password.hint':
        '{{minLength}} characters minimum, case sensitive.',
      'common.form.save': 'Save',
      'common.form.saveAsDraft': 'Draft',
      'common.form.cancel': 'Cancel',
      'common.form.deleteConfirm': 'Are you sure you want to delete this item?',
      'common.form.confirm': 'Confirm',
      'common.form.fieldRequired': 'This field is required',
      'common.form.enterValueFor': 'Enter value for ',
      'common.form.typeToSearchValueFor': 'Type to search value for ',

      'common.typeahead.notFound': 'Not Found',
      'common.typeahead.nothingFound': 'Nothing Found',
      'common.typeahead.create': 'Create',
      'common.typeahead.noMatchingUser': 'No matching user',

      // table
      'table.columnVisibility': 'Column visibility',
      'table.empty': 'There are no items to display',
      'table.loadMore': 'Load more',
      'table.delete': 'Delete',
      'table.edit': 'Edit',
      'table.download': 'Download',

      // common errors
      'errors.fields.fieldLongerThen': 'Field is longer than',
      'errors.fields.characters': 'characters',
      'errors.fields.emptyFieldError': "Field can't be empty",

      // login form
      'login.header': 'Login into account',
      'login.subHeader': 'Enter your email and password to your account',
      'login.signIn': 'Sing in',
      'login.register': 'Register',
      'login.continueWith': 'or continue with',
      'login.invalidCredentials': 'Invalid email or password',
      'login.terms':
        'By clicking continue, you agree to our Terms of Service and Privacy Policy.',

      'register.header': 'User Registration',
      'register.subHeader':
        'Enter your email and password to register your account',
      'register.fields.firstName': 'First Name',
      'register.fields.lastName': 'Last Name',
      'register.fields.email': 'Email',
      'register.fields.password': 'Password',
      'register.fields.confirmPassword': 'Confirm Password',
      'register.genericError': 'Error registering user',
      'register.error.passwordNotSame': 'The passwords should be the same',
      'register.success': 'User registered successfully',
      'register.terms':
        'By clicking register, you agree to our Terms of Service and Privacy Policy.',

      // organization list
      'organizationList.header': 'Organization List',
      'organizationList.subHeader':
        "Here's a list of all active organizations in your account",
      'organizationList.shareSuccess': 'Organization link copied to clipboard',
      'organizationList.menuItem.edit': 'Edit',
      'organizationList.menuItem.remove': 'Remove',
      'organizationList.deleteConfirm':
        'Are you sure you want to delete this organization?',
      'organizationList.userRole.user': 'Organization member',
      'organizationList.userRole.admin': 'Organization administrator',
      'organizationList.userRole.notInTheOrganization':
        'Not in this organization',

      // organization form
      'organizationForm.create.header': 'Organization Form',
      'organizationForm.create.subHeader':
        'Fill in the details of the new organization',
      'organizationForm.create.sucscefull': 'Organization created successfully',
      'organizationForm.create.error': 'Error creating organization',
      'organizationForm.edit.header': 'Edit organization',
      'organizationForm.edit.subHeader': 'Edit organization details',
      'organizationForm.edit.sucscefull': 'Organization updated successfully',
      'organizationList.column.name': 'Name',
      'organizationList.column.description': 'Description',
      'organizationForm.edit.error': 'Error updating organization',

      // payments list
      'payments.header': 'Payments',
      'payments.filters.placeholder': 'filter by paid and status',
      'payments.filters.selected': 'Selected',
      'payments.filters.paidTo': 'Paid to',
      'payments.filters.status': 'Status',
      'payments.subHeader': "Here's a list of all active payments",

      // payee list
      'payeeList.header': 'Payee List',
      'payeeList.subHeader':
        "Here's a list of all active payees in the account",
      'payeeList.menuItem.edit': 'Edit',
      'payeeList.menuItem.remove': 'Remove',
      'payeeList.deleteConfirm': 'Are you sure you want to delete this payee?',
      'payeeList.column.id': 'Id',
      'payeeList.column.user': 'User',
      'payeeList.column.nameOnCheck': 'Name On Check',
      'payeeList.column.email': 'Email',
      'payeeList.column.phone': 'Phone',
      'payeeList.column.address': 'Address',
      'payeeList.column.city': 'City',
      'payeeList.column.state': 'State',
      'payeeList.column.zip': 'Zip',
      'payeeList.column.user.firstName': 'User First Name',
      'payeeList.column.user.lastName': 'User Last Name',
      'payeeList.column.user.email': 'User Email',

      // payee form
      'payeeForm.create.header': 'Payee Form',
      'payeeForm.create.subHeader': 'Fill in the details of the new payee',
      'payeeForm.create.sucscefull': 'Payee created successfully',
      'payeeForm.create.error': 'Error creating payee',
      'payeeForm.edit.header': 'Edit payee',
      'payeeForm.edit.sucscefull': 'Payee updated successfully',
      'payeeForm.edit.subHeader': 'Edit payee details',
      'payeeForm.edit.error': 'Error updating payee',

      //bank list
      'bankList.header': 'Bank List',
      'bankList.subHeader': "Here's a list of all active banks in the account",
      'bankList.column.id': 'Id',
      'bankList.column.account': 'Account',
      'bankList.column.routing': 'Routing',
      'bankList.column.accountName': 'Account Name',
      'bankList.column.bankName': 'Bank Name',
      'bankList.column.companyName': 'Company Name',
      'bankList.column.address': 'Address',
      'bankList.column.city': 'City',
      'bankList.column.state': 'State',
      'bankList.column.zip': 'Zip',
      'bankList.column.createdOn': 'Created On',
      'bankList.deleteConfirm': 'Are you sure you want to delete this bank?',

      // bank form
      'bankForm.create.header': 'Bank Form',
      'bankForm.create.subHeader': 'Fill in the details of the new bank',
      'bankForm.create.sucscefull': 'Bank created successfully',
      'bankForm.create.error': 'Error creating bank',
      'bankForm.edit.header': 'Edit bank',
      'bankForm.edit.sucscefull': 'Bank updated successfully',
      'bankForm.edit.subHeader': 'Edit bank details',
      'bankForm.edit.error': 'Error updating bank',

      // payment list
      'paymentList.header': 'Payment List',
      'paymentList.subHeader':
        "Here's a list of all active payment in the account",
      'paymentList.deleteConfirm':
        'Are you sure you want to delete this payment?',
      'paymentList.column.id': 'Id',
      'paymentList.column.amount': 'Amount',
      'paymentList.column.bank': 'Bank Name',
      'paymentList.column.checkNumber': 'Check Number',
      'paymentList.column.checkDate': 'Check Date',
      'paymentList.column.note': 'Note',
      'paymentList.column.memo': 'Memo',
      'paymentList.column.name': 'Name',
      'paymentList.column.company': 'Company',
      'paymentList.column.nameOnCheck': 'Name On Check',
      'paymentList.column.referenceId': 'Reference Id',
      'paymentList.column.email': 'Email',
      'paymentList.column.phone': 'Phone',
      'paymentList.column.address': 'Address',
      'paymentList.column.city': 'City',
      'paymentList.column.state': 'State',
      'paymentList.column.zip': 'Zip',
      'paymentList.column.kId': 'KId',
      'paymentList.column.status': 'Status',
      'paymentList.column.payee': 'Payee',
      'paymentList.column.payee.label':
        'Type here to select existing payee, or create new one',
      'paymentList.column.payee.helpText':
        'Leave this field empty, if you would like to create a new payee',

      // bank form
      'paymentForm.create.header': 'Payment Form',
      'paymentForm.create.subHeader': 'Create of the new payment',
      'paymentForm.create.sucscefull': 'Payment created successfully',
      'paymentForm.create.error': 'Error creating payment',
      'paymentForm.edit.header': 'Edit payment',
      'paymentForm.edit.sucscefull': 'Payment updated successfully',
      'paymentForm.edit.subHeader': 'Edit payment details',
      'paymentForm.edit.error': 'Error updating payment',

      // user list
      'userList.header': 'User List',
      'userList.subHeader': "Here's a list of all active users in the account",
      'userList.deleteConfirm': 'Are you sure you want to delete this user?',
      'userList.column.firstName': 'First Name',
      'userList.column.lastName': 'Last Name',
      'userList.column.email': 'Email',
      'userList.column.userType': 'User Type',
      'userList.column.accessLevel': 'Access Level',
      'userList.column.organization': 'Organization',

      // user form
      'userForm.create.header': 'User Form',
      'userForm.create.subHeader': 'Fill in the details of the new user',
      'userForm.create.sucscefull': 'User created successfully',
      'userForm.create.error': 'Error creating user',
      'userForm.edit.header': 'Edit user',
      'userForm.edit.sucscefull': 'User updated successfully',
      'userForm.edit.subHeader': 'Edit user details',
      'userForm.edit.error': 'Error updating user',
    },
  },
  uk: {
    translation: {},
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    resources,
    debug: process.env.I18N_DEBUG === 'true',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
