import React, { ForwardedRef } from 'react';

import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import { Box, Tooltip, Typeahead } from 'components';
import { ControlProps } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { GroupBase } from 'react-select/dist/declarations/src/types';

import { _, classNames, reactSelectComponents } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

import { TypeaheadProps } from 'components/Typeahead/Typeahead';
import { IconButton } from 'components/index';

import { TypeaheadOption } from 'types/app';

const ClearButton = styled(IconButton)`
  margin-right: 1rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 1rem;
  color: ${props => props.theme.custom.palette.darkText};
`;

const ControlSearchIcon = styled(SearchIcon)`
  padding-left: 1rem;
  color: ${props => props.theme.custom.palette.primary900};
`;

const ControlInner = styled(Box)<{ focused?: boolean }>`
  border-radius: 4px;
  font-family: 'Inter';
  /* font-family: Source Sans Pro, serif; */
  font-size: 0.875rem;
  position: relative;
  border: 1px solid transparent;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: flex;
  font-size: 1rem;
  max-height: 8rem;
  min-width: 0;
  flex-grow: 1;
  flex-flow: column;
  align-items: stretch;
`;

const ControlWrapper = styled.div<{
  invalid?: boolean;
  maxHeight: string;
  isFlat?: boolean;
}>`
  width: 100%;
  max-width: 100%;

  max-height: ${props => props.maxHeight};
  min-height: 1rem;
  font-family: 'Inter';
  font-size: 0.875rem;
  border-radius: ${props => (props.isFlat ? '0px' : '4px')};
  border-width: ${props => (props.isFlat ? ' 0 0 1px 0' : '1px')};
  border-style: solid;
  border-color: ${props => (props.invalid ? '#d32f2f' : '#949494')};
  box-sizing: border-box;
  display: flex;
  &:hover {
    border-color: ${props => (props.invalid ? '#d32f2f' : '#e0e0e0')};
  }
  &.focused {
    border-width: ${props => (props.isFlat ? ' 0 0 1px 0' : '1px')};
    border-color: ${props =>
      props.invalid ? '#d32f2f' : props.theme.custom.palette.primary900};
    ${ControlInner} {
      border-width: 0;
    }
  }
`;

export const styles: StylesConfig<
  TypeaheadOption,
  boolean,
  GroupBase<TypeaheadOption>
> = {
  multiValue: (baseStyles, props) =>
    // @ts-ignore TODO: find out how to pass custom props to react select type
    props.selectProps.hasMultiStyling
      ? baseStyles
      : {
          ...baseStyles,
          padding: 0,
          margin: '4px',
          backgroundColor: 'transparent',
        },
  groupHeading: baseStyles => ({ ...baseStyles, paddingLeft: '1rem' }),
  multiValueLabel: (baseStyles, props) =>
    // @ts-ignore TODO: find out how to pass custom props to react select type
    props.selectProps.hasMultiStyling
      ? { ...baseStyles, fontWeight: '600', maxWidth: '20rem' }
      : {
          ...baseStyles,
          paddingLeft: '0.2rem',
          fontFamily: 'Inter',
          fontWeight: '400',
          fontSize: '0.8rem',
          lineHeight: '1.4375em',
          color: props.isDisabled ? 'rgba(0,0,0,0.38)' : 'rgba(0,0,0,0.87)',
        },
  multiValueRemove: (baseStyles, props) =>
    // @ts-ignore TODO: find out how to pass custom props to react select type
    props.selectProps.hasMultiStyling
      ? baseStyles
      : {
          ...baseStyles,
          display: 'none',
        },
  valueContainer: (baseStyles, props) => ({
    ...baseStyles,
    // @ts-ignore TODO: find out how to pass custom props to react select type
    flexWrap: props.selectProps.hasMultiStyling ? 'wrap' : 'nowrap',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  }),
  control: baseStyles => ({
    ...baseStyles,
    flexGrow: 1,
    minWidth: 0,
    minHeight: 0,
    fontStyle: 'normal',
    fontSize: '0.875rem !important',
    fontWeight: 400,
    lineHeight: 1.75,
    fontFamily: 'inherit',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'white',
  }),
  clearIndicator: baseStyles => ({
    ...baseStyles,
    display: 'none',
  }),
  loadingIndicator: baseStyles => ({
    ...baseStyles,
    position: 'absolute',
    right: 0,
    height: '1rem',
    backgroundColor: '#fff',
    zIndex: 2,
  }),
};

type ExtendedControlProps = ControlProps<
  TypeaheadOption,
  true,
  GroupBase<TypeaheadOption>
> & {
  selectProps: {
    isFlat?: boolean;
    hasError?: boolean;
    maxHeight?: string;
    searchIcon?: boolean;
    clearButtonStyle?: React.CSSProperties;
    hasMultiStyling?: boolean;
    inputValue?: string;
  };
};

export const Control: React.FC<ExtendedControlProps> = ({
  children,
  ...props
}) => {
  return (
    <ControlWrapper
      isFlat={props.selectProps.isFlat}
      className={classNames({
        focused: props.isFocused,
      })}
      invalid={props.selectProps.hasError}
      maxHeight={props.selectProps.maxHeight ?? '2.5rem'}
    >
      <Tooltip
        placement="top-start"
        enterDelay={TOOLTIP_APPEAR_DELAY}
        enterNextDelay={TOOLTIP_APPEAR_DELAY}
        title={
          props.isMulti
            ? (props.getValue() || []).map(it => it.label).join(', ')
            : props.getValue()?.[0]?.label ?? ''
        }
      >
        <ControlInner
          className={classNames({
            focused: props.isFocused,
          })}
        >
          <reactSelectComponents.Control {...props}>
            {/* @ts-ignore find out how to pass custom props into Select type */}
            {props.selectProps.searchIcon && <ControlSearchIcon />}
            {children}
            {!props.isDisabled &&
              (props.selectProps.inputValue ||
                !_.isEmpty(props.getValue())) && (
                <ClearButton onClick={props.clearValue}>
                  {/* @ts-ignore find out how to pass custom props into Select type */}
                  <StyledCloseIcon style={props.selectProps.clearButtonStyle} />
                </ClearButton>
              )}
          </reactSelectComponents.Control>
        </ControlInner>
      </Tooltip>
    </ControlWrapper>
  );
};

type Props = Omit<TypeaheadProps, 'onChange'> & {
  searchIcon?: boolean;
  onChange?: (option: any) => void;
  formatCreateLabel?: (inputValue: string) => string;
  createOptionPosition?: string;
  isValidNewOption?: (
    inputValue: string,
    selectedOption: TypeaheadOption[] | null,
    autosuggestOptions: TypeaheadOption[],
  ) => boolean;
};

export const TypeaheadFormField = React.forwardRef(
  (props: Props, ref: ForwardedRef<any>) => {
    return (
      <Typeahead
        ref={ref}
        styles={styles}
        components={{ Control }}
        onChange={props.onChange}
        {...props}
        // @ts-ignore TODO: find out the the right type
        hasMultiStyling={props.isMulti}
        hideDropdownIndicator
      />
    );
  },
);
