import React, { KeyboardEventHandler } from 'react';

import styled, { DefaultTheme, useTheme } from 'styled-components';

import { TextFieldProps } from '@mui/material/TextField/TextField';
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from 'components';
import { Definitions } from 'imask';
import { ControllerProps } from 'react-hook-form/dist/types/controller';

import { Control, FieldError, IMaskInput } from 'third-party';
import { Controller, useTranslation } from 'third-party';

import { isEmpty } from 'utils/helper';

export const getLabelColor = (hasError: boolean, theme: DefaultTheme) =>
  hasError ? theme.custom.palette.error500 : theme.custom.palette.darkText;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledTextField = styled(TextField)<{ variant?: string }>`
  width: inherit;
  input,
  textArea {
    &::placeholder {
      opacity: 1;
      color: ${props => props.theme.custom.palette.gray};
    }

    &::-ms-input-placeholder {
      color: ${props => props.theme.custom.palette.gray};
    }
  }
`;

export const StyledFormFieldLabel = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-bottom: 0.2rem;
`;

export const StyledFormHelperText = styled(FormHelperText)`
  margin: 0 0 0 auto;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.error500};
`;

const SelectPlaceholder = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  padding: 0 1rem 1rem 1rem;
  justify-content: center;
  align-items: center;
`;

export type FormFieldProps = {
  fieldName: string;
  label?: string;
  fieldError?: FieldError; // error object from react-hook-form
  requiredErrorMessage?: string; // custom message
  showError?: boolean;
  isUpdating?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
  selectOptions?: JSX.Element[] | JSX.Element;
  control: Control<any, any>;
  maxLength?: number;
  placeholder?: TextFieldProps['placeholder'];
  defaultValue?: TextFieldProps['defaultValue'];
  rules?: any;
  type?: TextFieldProps['type'];
  size?: TextFieldProps['size'];
  inputProps?: TextFieldProps['inputProps'];
  InputProps?: TextFieldProps['InputProps'];
  showLoadMore?: boolean;
  handleLoadMoreClick?: () => void;
  loadMorePending?: boolean;
  onKeyDownCallback?: () => void;
  onKeyUp?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  multiline?: boolean;
  showIsRequiredMark?: boolean;
  className?: string;
  rows?: number;
  render?: ControllerProps['render'];
  mask?: any;
  maskDefinitions?: Definitions;
  maskOptions?: any;
};

interface TextMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  maskdefinitions?: Definitions;
}

const TextMaskCustom = React.forwardRef<HTMLElement, TextMaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, maskdefinitions, ...other } = props;
    return (
      <IMaskInput
        {...other}
        definitions={maskdefinitions}
        // @ts-ignore
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  },
);

export const FormField: React.FC<FormFieldProps> = ({
  showError = true,
  fieldError,
  requiredErrorMessage,
  isUpdating,
  isDisabled,
  fieldName,
  label,
  placeholder,
  isRequired = true,
  showIsRequiredMark = true,
  selectOptions,
  isLoading,
  control,
  defaultValue,
  className,
  type = 'text',
  size = 'small',
  maxLength,
  rules = {},
  inputProps,
  InputProps,
  showLoadMore,
  // handleLoadMoreClick,
  // loadMorePending,
  onKeyDownCallback,
  multiline,
  rows,
  mask,
  maskDefinitions,
  maskOptions = {},
  render,
  onChange,
  onBlur,
  onKeyUp,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const titleColor = getLabelColor(showError && !!fieldError?.message, theme);

  const tooLongValueError = `${t(
    'errors.fields.fieldLongerThen',
  )} ${maxLength} ${t('errors.fields.characters')}`;

  const selectProps = (field: { value: any }) => {
    let displayValue = '';

    if (isLoading) {
      displayValue = t('common.loading');
    } else {
      if (placeholder && !field.value) {
        displayValue = placeholder;
      }
    }

    return displayValue
      ? {
          displayEmpty: true,
          renderValue: () => (
            <SelectPlaceholder>{displayValue}</SelectPlaceholder>
          ),
        }
      : {};
  };

  const isSelectDisabled = type === 'select' && isEmpty(selectOptions);
  const isFieldDisabled =
    isUpdating || isDisabled || isLoading || isSelectDisabled;

  const handleKeyDownPress: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.keyCode === 13 && onKeyDownCallback) {
      onKeyDownCallback();
    }
  };

  return (
    <StyledFormControl
      style={{ marginTop: label ? '0.6rem' : '0.4rem' }}
      className={className}
    >
      <StyledFormFieldLabel container>
        {label && (
          <Typography variant="h4" color={titleColor}>
            {label}
            {isRequired && showIsRequiredMark ? ' *' : ''}
          </Typography>
        )}
        {showError && fieldError?.message && (
          <StyledFormHelperText>{fieldError?.message}</StyledFormHelperText>
        )}
      </StyledFormFieldLabel>
      <Controller
        rules={{
          required: {
            value: isRequired,
            message: requiredErrorMessage || '',
          },
          ...(maxLength
            ? {
                maxLength: {
                  value: maxLength,
                  message: tooLongValueError,
                },
              }
            : {}),
          ...rules,
          validate: {
            ...(isRequired
              ? {
                  errorOnSpaces: (value: string | object) => {
                    if (typeof value === 'string') {
                      return !!value.trim() || requiredErrorMessage;
                    }
                    return value !== null || requiredErrorMessage;
                  },
                }
              : {}),
            ...(rules?.validate ? rules.validate : {}),
          },
        }}
        control={control}
        name={fieldName}
        render={props =>
          render ? (
            render(props)
          ) : (
            <StyledTextField
              {...props.field}
              size={size}
              type={type}
              multiline={multiline}
              rows={rows}
              defaultValue={defaultValue}
              disabled={isFieldDisabled}
              select={!!selectOptions}
              SelectProps={selectProps(props.field)}
              placeholder={isLoading ? t('common.loading') : placeholder}
              variant="outlined"
              autoComplete="on"
              error={showError && !!fieldError?.message}
              // @ts-ignore
              onKeyUp={onKeyUp}
              onChange={event => {
                props.field.onChange(event);
                onChange?.(event);
              }}
              onBlur={onBlur}
              inputProps={{
                ...inputProps,
                mask,
                maskdefinitions: maskDefinitions,
                ...(mask && maskOptions),
              }}
              onKeyDown={handleKeyDownPress}
              InputProps={{
                ...InputProps,
                ...(mask
                  ? {
                      inputComponent: TextMaskCustom as any,
                    }
                  : {}),
              }}
            >
              {selectOptions}
              {showLoadMore && (
                <LoadMoreWrapper>
                  {/* <LoadingButton
                    variant="contained"
                    onClick={handleLoadMoreClick}
                    loading={loadMorePending}
                  >
                    {t('common.loadMore')}
                  </LoadingButton> */}
                </LoadMoreWrapper>
              )}
            </StyledTextField>
          )
        }
      />
    </StyledFormControl>
  );
};
