import * as SwaggerAPI from './swaggerApi';
import { NonNullableObject } from './utils';

import { APIServerError } from 'utils/sra/types';

// generic
export enum APISortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type APIGenericList<ItemType> = {
  result: ItemType[];
  pageNumber: number;
  pageSize: number;
  count: number;
};

export type APIResponse<PayloadT> =
  | { error?: false; payload: PayloadT }
  | APIServerError;

// app module
export type APIOrganization = Required<
  NonNullableObject<SwaggerAPI.APIOrganizationModel>
>;
export type APIUserRegister = SwaggerAPI.APIUserRegisterModel;
export type APIUserOrganization = SwaggerAPI.APIUserOrganizationModel & {
  isSelected: boolean;
};

// user module
export { APIUserType, APIAccessLevel, APIPaymentStatus } from './swaggerApi';

// organization module
export type APIOrganizationSearchResult = APIGenericList<APIOrganization>;
export type APIOrganizationSearchFilter =
  SwaggerAPI.APIOrganizationSearchFilter;

// payee module
export type APIPayee = Required<NonNullableObject<SwaggerAPI.APIPayeeModel>>;
export type APIPayeeSearchResult = APIGenericList<APIPayee>;
export type APIPayeeSearchFilter = SwaggerAPI.APIPayeeSearchFilter;

// payments module
export type APIBank = Required<NonNullableObject<SwaggerAPI.APIBankModel>>;
export type APIBankSearchResult = APIGenericList<APIBank>;
export type APIBankSearchFilter = SwaggerAPI.APIBankSearchFilter;

export type APIPaymentRequest = Required<
  NonNullableObject<SwaggerAPI.APIPaymentRequestModel>
>;
export type APIPayment = Required<
  NonNullableObject<SwaggerAPI.APIPaymentModel>
>;
export type APIPaymentSearchResult = APIGenericList<APIPayment>;
export type APIPaymentSearchFilter = SwaggerAPI.APIPaymentSearchFilter;

// user module
export type APIUser = Omit<
  Required<SwaggerAPI.APIUserModel>,
  'userOrganizations'
> & {
  userOrganizations: APIUserOrganization[];
};

export type APIUserSearchResult = APIGenericList<APIUser>;
export type APIUserSearchFilter = SwaggerAPI.APIUserSearchFilter;
