import { State } from 'modules';

export const loadPayeesPending = (state: State) =>
  state.payee.loadPayeesPending;
export const loadPayeePending = (state: State) => state.payee.loadPayeePending;

export const payee = (state: State) => state.payee.payee;

export const loadMorePayeesPending = (state: State) =>
  state.payee.loadPayeesPending;
export const allPayeesLoaded = (state: State) => state.payee.allPayeesLoaded;
export const payeeList = (state: State) => state.payee.payeeList;
export const payeeFilters = (state: State) => state.payee.filters;
export const payeesListLoaded = (state: State) => state.payee.payeeListLoaded;
