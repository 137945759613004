import React, { useEffect, useRef } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { Select } from 'components';

import { ColumnFilteringProps } from 'components/Table/types';

type Props = ColumnFilteringProps & { children: React.ReactNode };

export const ListFilter: React.FC<Props> = ({
  value,
  columnId,
  children,
  onFilterChange,
}) => {
  const ref = useRef<any>(null);

  const handleChange = (event: SelectChangeEvent) => {
    onFilterChange?.(columnId.toString(), event.target.value);
  };

  useEffect(() => {
    if (value) {
      ref.current.value = value;
    }
  }, [value]);

  return (
    <Select
      inputRef={ref}
      size="small"
      variant="standard"
      fullWidth
      labelId="demo-select-small-label"
      id="demo-select-small"
      value={value ?? ''}
      displayEmpty
      sx={{
        maxHeight: '1.4rem',
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
      }}
      onChange={handleChange}
    >
      {children}
    </Select>
  );
};
