import { LinkProps } from '@mui/material/Link';
import { LinkBehavior } from 'components';

export const palette = {
  light100: '#E7E5E4',
  main: '#31887E',
  searchResult: '#ffff00',

  // text colors
  blackText: '#0F172A',
  whiteText: '#FFF',
  subTittle: '#71717A',
  borderColor: '#E4E4E7',

  // primary color
  primary50: '#bde5ca',
  primary100: '#3ba76975',
  primary200: '#3ba76999',
  primary300: '#24b260',
  primary400: '#24b260',
  primary500: '#00a566',
  primary600: '#00996b',
  primary700: '#008b6f',
  primary800: '#007e71',
  primary900: '#007170',

  // secondary
  secondary50: '#E6EEF0',
  secondary100: '#C1D5DA',
  secondary500: '#307282',
  secondary600: '#2B6A7A',
  secondary700: '#245F6F',
  secondary800: '#1E5565',
  secondary900: '#134252',

  // grey
  grey50: '#F9FAFB',
  grey100: '#F3F4F6',
  grey200: '#E5E7EB',
  grey300: '#E5E7EB',
  grey400: '#9CA3AF',
  grey500: '#6B7280',
  grey600: '#4B5563',
  grey700: '#374151',
  grey800: '#1F2937',
  grey900: '#111827',

  backgroundTheme: '#FCF9F8',
  backgroundThemeLight: '#FFFFFF',
  textOnDark: '#F3F4F6',

  warning50: '#FFF7ED',
  warning600: '#EA580C',
  warning700: '#C2410C',
  warning800: '#9A3412',
  danger400: '#D9544D',
  danger600: '#CD3029',
  danger700: '#C72923',
  danger800: '#C1221D',
  danger900: '#B61612',

  muted50: '#F0F0EF',
  muted100: '#DAD8D7',
  muted200: '#C1BFBD',
  muted300: '#A8A5A2',
  muted400: '#96918E',
  muted500: '#837E7A',
  muted600: '#7B7672',
  muted700: '#706B67',
  muted800: '#66615D',
  muted900: '#534E4A',
  info50: '#F0F9FF',
  info500: '#0EA5E9',
  info700: '#0369A1',
  info900: '#0C4A6E',
  purple800: '#693082',
  amber500: '#F59E0B',
  violet700: '#6D28D9',
  purple50: '#FAF5FF',
  purple700: '#7E22CE',

  bluegray400: '#94A3B8',
  bluegray600: '#475569',
  truegray300: '#D4D4D4',
  truegray400: '#A3A3A3',
  truegray600: '#525252',
  truegray700: '#404040',
  truegray800: '#262626',
  truegray900: '#171717',

  teal600: '#0D9488',
  darkText: '#1C1917',
  absoluteDark: '#000000',
  info600: '#0284C7',
  rowHighlight: '#c9e9fd',
  rowCancellationSubmittedHighlight: '#FFE4E6',
  rowUnreadHighlight: '#faf5ff',
  error500: '#B02D26',
  error600: '#A92822',
  error800: '#971C17',
  notificationBadge: '#DC2626',

  white: '#FFFFFF',
  success500: '#577A0E',
  success600: '#4F720C',
  success700: '#46670A',
  success800: '#3C5D08',
};

export const dimensions = {
  appBarHeight: 6,
  notificationPadding: 1,
  appDrawerWidth: 17.3,
  viewHorizontalPadding: 3.5,
  viewVerticalPadding: 2.5,
};

export const zIndex = {
  mainNavigation: 7,
  orderLeftPane: 4,
  orderChat: 3,
  batchingOrderNumberPopup: 2,
};

export const time = {
  tooltipEnterDelay: 1000,
  tooltipEnterNextDelay: 1000,
};

export const paddings = {
  componentPaddingSmall: '1.6rem',
};

export const popperTimeSx = {
  '& .MuiClock-amButton, & .MuiClock-pmButton': {
    width: '4.2rem',
  },
  '& .MuiClockNumber-root': {
    fontSize: '1.6rem',
  },
};

export const themeConfig = {
  custom: {
    palette,
    dimensions,
    zIndex,
    time,
    paddings,
  },
  palette: {
    primary: {
      main: palette.primary900,
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Inter', 'serif'].join(','),
    h1: {
      fontSize: '1.4rem !important',
      color: palette.truegray700,
      fontWeight: 800,
      lineHeight: 1.25,
    },
    h2: {
      fontStyle: 'normal',
      fontSize: '1.2rem !important',
      color: palette.blackText,
      fontWeight: 600,
      lineHeight: 1.25,
      letterSpacing: '-0.006em',
    },
    h3: {
      fontStyle: 'normal',
      fontSize: '0.875rem !important',
      color: palette.blackText,
      fontWeight: 400,
      lineHeight: 1.75,
    },
    h4: {
      fontStyle: 'normal',
      fontSize: '0.8rem !important',
      color: palette.blackText,
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: '-0.006em',
    },
    h5: {
      fontStyle: 'normal',
      fontSize: '0.75rem !important',
      color: palette.blackText,
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: '-0.006em',
    },
    h6: {},
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.875rem',
      color: palette.subTittle,
    },
    subtitle2: {
      fontSize: '1.6rem !important',
      color: palette.absoluteDark,
      fontWeight: 900,
      lineHeight: 1.25,
      textTransform: 'uppercase' as const,
    },
    body1: {
      fontStyle: 'normal',
      fontSize: '0.85rem !important',
      color: palette.blackText,
      letterSpacing: '-0.006em',
    },
    p: {
      fontStyle: 'normal',
      fontSize: '0.85rem !important',
      color: palette.blackText,
      letterSpacing: '-0.006em',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          input: {
            fontSize: '0.8rem',
            padding: '0.8rem',
          },
          '& input::placeholder': {
            color: palette.subTittle,
            opacity: 1,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        wrapper: {
          borderColor: palette.absoluteDark,
          fontSize: '0.9rem',
          textTransform: 'uppercase',
        },
      },
    },

    // menu
    MuiMenu: {
      styleOverrides: {
        root: {
          padding: '1rem',
          '.MuiPaper-root': {
            borderColor: palette.borderColor,
          },
          ul: {
            margin: '0.3rem',
            padding: '0',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          marginBottom: '0.2rem',
          fontWeight: 400,
          borderRadius: '0.3rem',
          color: palette.primary700,
          height: '2rem',
          '&:hover': {
            backgroundColor: palette.primary100,
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white,
          border: `1px solid ${palette.primary100}`,
        },
      },
    },

    // MuiCard
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.borderColor}`,
          borderRadius: '0.5rem',
          boxShadow: '0 0 1rem 0 rgba(0, 0, 0, 0.1)',
        },
      },
    },

    // navigation overriding
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          lineHeight: '2rem',
          fontWeight: 600,
          color: '#000',
          fontSize: '0.9rem',
          paddingLeft: '2.5rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '1.8rem',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          span: {
            fontSize: '0.8rem !important',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.375rem',
          fontSize: '0.875rem',
          marginBottom: '0.2rem',
          fontWeight: 500,
          color: palette.primary700,
          height: '2rem',
          '&.Mui-selected': {
            backgroundColor: palette.primary100,
          },
          '&.Mui-selected:hover': {
            backgroundColor: palette.primary200,
          },
          '&:hover': {
            backgroundColor: palette.primary200,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontSize: '0.5rem !important',
          color: palette.blackText,
          fontWeight: 500,
          lineHeight: 1.25,
          letterSpacing: '-0.006em',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '2.9rem',
        },
      },
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
};
