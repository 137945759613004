import { State } from 'modules';

export const loadOrganizationsPending = (state: State) =>
  state.organization.loadOrganizationsPending;
export const loadOrganizationPending = (state: State) =>
  state.organization.loadOrganizationPending;

export const organization = (state: State) => state.organization.organization;

export const loadMoreOrganizationsPending = (state: State) =>
  state.organization.loadOrganizationsPending;
export const allOrganizationsLoaded = (state: State) =>
  state.organization.allOrganizationsLoaded;
export const organizationList = (state: State) =>
  state.organization.organizationsList;
export const organizationFilters = (state: State) => state.organization.filters;
export const organizationsListLoaded = (state: State) =>
  state.organization.organizationsListLoaded;
