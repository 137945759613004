import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Button,
  FormField,
  Loader,
  PageHeader,
  TypeaheadFormField,
} from 'components';
import { useNotifications } from 'hooks';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';
import { usePayee } from 'modules/payee';

import { appSelectors, payeeSelectors } from 'selectors';

import { APIPayee, APIResponse } from 'types/api';
import { TypeaheadOption } from 'types/app';

enum FormFields {
  NameOnCheck = 'nameOnCheck',
  Email = 'email',
  Phone = 'phone',
  Address = 'address',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  UserId = 'userId',
}

type FormData = {
  [FormFields.NameOnCheck]: string;
  [FormFields.Email]: string;
  [FormFields.Phone]: string;
  [FormFields.Address]: string;
  [FormFields.City]: string;
  [FormFields.State]: string;
  [FormFields.Zip]: string;
  [FormFields.UserId]: {
    label: string | null | undefined;
    value: string | undefined;
  };
};

type CreateEditProps = {
  onModalClose: () => void;
  organizationId?: string | null;
  payeeId?: string | null;
};

export const PayeeCreateEditForm: React.FC<CreateEditProps> = ({
  onModalClose,
  organizationId,
  payeeId,
}) => {
  const { loadPayee, clearPayee } = usePayee();
  const payee = useSelector(payeeSelectors.payee);
  const isEditMode = !!payeeId;

  useEffect(() => {
    if (payeeId) {
      loadPayee(payeeId);
    }
    return () => {
      clearPayee();
    };
  }, [payeeId, loadPayee, clearPayee]);

  if (isEditMode && !payee) {
    return <Loader />;
  }

  return (
    <CreateEditForm
      payee={payee}
      organizationId={organizationId}
      isEditMode={isEditMode}
      onModalClose={onModalClose}
    />
  );
};

type Props = {
  payee: APIPayee | null;
  organizationId?: string | null;
  onModalClose: () => void;
  isEditMode: boolean;
};

export const CreateEditForm: React.FC<Props> = ({
  payee,
  organizationId,
  isEditMode,
  onModalClose,
}) => {
  const { error, success } = useNotifications();
  const { updatePayee, createPayee } = usePayee();
  const { loadUserTypeahead } = useCache();
  const { t } = useTranslation();
  const isOrganizationSelected = !!organizationId;
  const userId = useSelector(appSelectors.userId);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.NameOnCheck]: payee?.nameOnCheck || '',
      [FormFields.Email]: payee?.email || '',
      [FormFields.Phone]: payee?.phone || '',
      [FormFields.Address]: payee?.address || '',
      [FormFields.City]: payee?.city || '',
      [FormFields.State]: payee?.state || '',
      [FormFields.Zip]: payee?.zip || '',
      [FormFields.UserId]: {
        label: payee?.user?.firstName + ' ' + payee?.user?.lastName,
        value: payee?.user?.id,
      },
    },
  });

  const isFormPending = false;

  const handleCancel = () => {
    onModalClose();
  };

  const onSubmit = async (formData: FormData) => {
    if (isEditMode && payee) {
      const result = await updatePayee(payee?.id, {
        ...formData,
        userId: formData.userId?.value,
      } as any as APIPayee);
      if (result.error) {
        error(result.payload.body?.title || t('payeeForm.edit.error'));
      } else {
        success(t('payeeForm.edit.sucscefull'));
        onModalClose();
      }
      return;
    }

    // create
    const result = await createPayee({
      ...formData,
      userId: userId,
    } as any as APIPayee);
    if (result.error) {
      error(result.payload.body?.title || t('payeeForm.create.error'));
    } else {
      success(t('payeeForm.create.sucscefull'));
      onModalClose();
    }
  };

  const loadUsers = async (value: string) => {
    const result: APIResponse<{ name: string; id: string }[]> =
      await loadUserTypeahead(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it.name,
        value: it.id,
      }));
    }
    return {
      options,
    };
  };

  const onUserChange = useCallback(
    (option: TypeaheadOption | null) => {
      setValue(FormFields.UserId, option || { label: '', value: undefined }, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  return (
    <Box padding="1.5rem">
      <form>
        <PageHeader
          header={
            isEditMode
              ? t('payeeForm.edit.header')
              : t('payeeForm.create.header')
          }
          subHeader={
            isEditMode
              ? t('payeeForm.edit.subHeader')
              : t('payeeForm.create.subHeader')
          }
        >
          <Box display="flex" columnGap="1rem">
            <Button
              type="submit"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
              size="medium"
              color="primary"
            >
              {t('common.form.save')}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={handleCancel}
              size="medium"
              color="error"
            >
              {t('common.form.cancel')}
            </Button>
          </Box>
        </PageHeader>

        {isOrganizationSelected && (
          <FormField
            fieldName={FormFields.UserId}
            label={t('payeeList.column.user')}
            fieldError={errors[FormFields.UserId] as FieldError}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            render={() => (
              <TypeaheadFormField
                placeholder={
                  t('common.form.typeToSearchValueFor') +
                  t('payeeList.column.user')
                }
                defaultValue={
                  payee?.user
                    ? {
                        label: payee.user.firstName + ' ' + payee.user.lastName,
                        value: payee.user.id,
                      }
                    : undefined
                }
                loadOptions={loadUsers}
                onChange={onUserChange}
                isDisabled={isFormPending}
              />
            )}
          />
        )}

        <FormField
          fieldName={FormFields.NameOnCheck}
          fieldError={errors[FormFields.NameOnCheck] as FieldError}
          label={t('payeeList.column.nameOnCheck')}
          placeholder={
            t('common.form.enterValueFor') + t('payeeList.column.nameOnCheck')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.Email}
          fieldError={errors[FormFields.Email] as FieldError}
          label={t('payeeList.column.email')}
          placeholder={
            t('common.form.enterValueFor') + t('payeeList.column.email')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.Phone}
          fieldError={errors[FormFields.Phone] as FieldError}
          label={t('payeeList.column.phone')}
          placeholder={
            t('common.form.enterValueFor') + t('payeeList.column.phone')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={25}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.Address}
          fieldError={errors[FormFields.Address] as FieldError}
          label={t('payeeList.column.address')}
          placeholder={
            t('common.form.enterValueFor') + t('payeeList.column.address')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={255}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.City}
          fieldError={errors[FormFields.City] as FieldError}
          label={t('payeeList.column.city')}
          placeholder={
            t('common.form.enterValueFor') + t('payeeList.column.city')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.State}
          fieldError={errors[FormFields.State] as FieldError}
          label={t('payeeList.column.state')}
          placeholder={
            t('common.form.enterValueFor') + t('payeeList.column.state')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={2}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.Zip}
          fieldError={errors[FormFields.Zip] as FieldError}
          label={t('payeeList.column.zip')}
          placeholder={
            t('common.form.enterValueFor') + t('payeeList.column.zip')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={25}
          isRequired
          isDisabled={isFormPending}
        />
      </form>
    </Box>
  );
};
