import React from 'react';

import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

import {
  dashboardUrl,
  organizationListUrl,
  userPayeeListUrl,
  userPaymentListUrl,
} from 'constants/url';

import { MenuType } from 'types/app';

export const UserMenu = (): MenuType[] => [
  {
    subHeader: 'User menu',
    items: [
      {
        url: () => dashboardUrl(),
        text: 'Dashboard',
        icon: <BarChartRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => userPaymentListUrl(),
        text: 'Payments',
        icon: <CreditCardRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => userPayeeListUrl(),
        text: 'Payee',
        icon: <PaymentsOutlinedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => organizationListUrl(),
        text: 'Organization',
        icon: <WorkOutlineOutlinedIcon color="primary" fontSize="small" />,
      },
    ],
  },
];
