import React from 'react';

import { Chip, MenuItem } from 'components';

import { dayjs } from 'third-party';

import { DISPLAY_DATE_FORMAT } from 'constants/common';

import { ListFilter } from 'components/Table/Filtering/ListFilter';
import { ColumnFilteringProps, TableColumn } from 'components/Table/types';

import { APIPayment, APIPaymentStatus } from 'types/api';

const PaymentColors = {
  [APIPaymentStatus.Draft]: '#D3D3D3',
  [APIPaymentStatus.Requested]: '#addce6',
  [APIPaymentStatus.Created]: '#fbf198',
  [APIPaymentStatus.Void]: '#FFB6C1',
  [APIPaymentStatus.TryToPrint]: '#B0E0E6',
  [APIPaymentStatus.CouldNotPrint]: '#FFA07A',
  [APIPaymentStatus.Printed]: '#90EE90',
  [APIPaymentStatus.Emailed]: '#98FB98',
  [APIPaymentStatus.Mailed]: '#8FBC8F',
  [APIPaymentStatus.Expired]: '#F08080',
  [APIPaymentStatus.Cleared]: '#90EE90',
};

export const PaymentStatusFilter: React.FC<ColumnFilteringProps> = props => {
  return (
    <ListFilter {...props}>
      <MenuItem value={APIPaymentStatus.Draft}>None</MenuItem>
      <MenuItem value={APIPaymentStatus.Requested}>Requested</MenuItem>
      <MenuItem value={APIPaymentStatus.Created}>Created</MenuItem>
      <MenuItem value={APIPaymentStatus.Void}>Void</MenuItem>
      <MenuItem value={APIPaymentStatus.TryToPrint}>TryToPrint</MenuItem>
      <MenuItem value={APIPaymentStatus.CouldNotPrint}>CouldNotPrint</MenuItem>
      <MenuItem value={APIPaymentStatus.Printed}>Printed</MenuItem>
      <MenuItem value={APIPaymentStatus.Emailed}>Emailed</MenuItem>
      <MenuItem value={APIPaymentStatus.Mailed}>Mailed</MenuItem>
      <MenuItem value={APIPaymentStatus.Expired}>Expired</MenuItem>
      <MenuItem value={APIPaymentStatus.Cleared}>Cleared</MenuItem>
      <MenuItem value="" />
    </ListFilter>
  );
};

export const paymentColumns: TableColumn<APIPayment>[] = [
  {
    dataId: 'id',
    label: 'paymentList.column.id',
    minWidth: 80,
    width: 140,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'status',
    label: 'paymentList.column.status',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
    customFilter: PaymentStatusFilter,
    formatter: value => ({
      value: 'test',
      element: (
        <Chip
          label={value}
          style={{
            backgroundColor: PaymentColors[value as APIPaymentStatus],
          }}
        />
      ),
    }),
  },
  {
    dataId: 'bank.bankName',
    label: 'paymentList.column.bank',
    minWidth: 80,
    width: 180,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'amount',
    label: 'paymentList.column.amount',
    minWidth: 80,
    width: 120,
    hidden: false,
    sortable: true,
    formatter: value => ({ value: '$' + value.toString() }),
    resizable: true,
  },
  {
    dataId: 'checkNumber',
    label: 'paymentList.column.checkNumber',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'checkDate',
    label: 'paymentList.column.checkDate',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    formatter: value => ({ value: dayjs(value).format(DISPLAY_DATE_FORMAT) }),
    resizable: true,
  },
  {
    dataId: 'note',
    label: 'paymentList.column.note',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'memo',
    label: 'paymentList.column.memo',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'name',
    label: 'paymentList.column.name',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'company',
    label: 'paymentList.column.company',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.nameOnCheck',
    label: 'paymentList.column.nameOnCheck',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'referenceId',
    label: 'paymentList.column.referenceId',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.email',
    label: 'paymentList.column.email',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.phone',
    label: 'paymentList.column.phone',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.address',
    label: 'paymentList.column.address',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.city',
    label: 'paymentList.column.city',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.state',
    label: 'paymentList.column.state',
    minWidth: 80,
    width: 80,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.zip',
    label: 'paymentList.column.zip',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'kId',
    label: 'paymentList.column.kId',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
];
