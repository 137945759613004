import { State } from 'modules';

export const loadBanksPending = (state: State) => state.bank.loadBanksPending;
export const loadBankPending = (state: State) => state.bank.loadBankPending;

export const bank = (state: State) => state.bank.bank;

export const loadMoreBanksPending = (state: State) =>
  state.bank.loadBanksPending;

export const allBanksLoaded = (state: State) => state.bank.allBanksLoaded;
export const bankList = (state: State) => state.bank.banksList;
export const bankFilters = (state: State) => state.bank.filters;
export const banksListLoaded = (state: State) => state.bank.banksListLoaded;
