import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  Typography,
} from 'components';

import { styled, useTheme } from 'third-party';

import { getAvatarName } from 'utils/helper';

const PointerBox = styled.div<{ isPointer: boolean }>`
  cursor: ${props => (props.isPointer ? 'pointer' : 'default')};
  user-select: none;
`;

type Props<TItem> = {
  item: TItem;
  title: string;
  width?: string;
  height?: string;
  description?: string | null;
  subTitle?: string;
  menuItems?: (item: TItem, handleClose: () => void) => React.ReactNode;
  cardActions?: (item: TItem) => React.ReactNode;
  onClick?: (item: TItem) => void;
  children?: React.ReactNode;
};

export const CardBox = <TItem,>({
  item,
  title,
  subTitle,
  description,
  width,
  height,
  onClick,
  menuItems,
  cardActions,
  children,
}: Props<TItem>) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menu = menuItems?.(item, handleClose);
  return (
    <Card>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        sx={{ width: width ?? 300, height: height ?? 220 }}
      >
        <CardHeader
          avatar={
            <PointerBox isPointer={!!onClick} onClick={() => onClick?.(item)}>
              <Avatar sx={{ bgcolor: theme.custom.palette.primary900 }}>
                {getAvatarName(title)}
              </Avatar>
            </PointerBox>
          }
          action={
            menu && (
              <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            )
          }
          title={title}
          subheader={subTitle}
        />
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          justifyContent="space-between"
        >
          <PointerBox isPointer={!!onClick} onClick={() => onClick?.(item)}>
            <CardContent>
              {description && (
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                >
                  {description}
                </Typography>
              )}
              {children}
            </CardContent>
          </PointerBox>
          {cardActions && (
            <CardActions disableSpacing>{cardActions(item)}</CardActions>
          )}
        </Box>
        {menu && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {menu}
          </Menu>
        )}
      </Box>
    </Card>
  );
};
