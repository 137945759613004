import { useMemo } from 'react';

import { State } from 'modules';

import { bindActionCreators, useDispatch } from 'third-party';

import { DEFAULT_PAGE_SIZE } from 'constants/common';
import {
  organizationEndpoint,
  organizationSingleEndpoint,
} from 'constants/endpoints';

import { getPageNumberAfterLoadMore } from 'utils/helper';
import { httpMethod, simplifyBuilder } from 'utils/sra';

import {
  APIOrganization,
  APIOrganizationSearchFilter,
  APIOrganizationSearchResult,
  APISortOrder,
} from 'types/api';

export type OrganizationState = {
  organizationsList: APIOrganization[];
  organization: APIOrganization | null;
  loadOrganizationPending: boolean;
  loadOrganizationsPending: boolean;
  allOrganizationsLoaded: boolean;
  organizationsListLoaded: boolean;
  filters: APIOrganizationSearchFilter;
  pagination: {
    pageNumber: APIOrganizationSearchResult['pageNumber'];
    pageSize: APIOrganizationSearchResult['pageSize'];
  };
};

export const organizationState: OrganizationState = {
  organizationsList: [],
  organization: null,
  loadOrganizationPending: false,
  filters: {
    searchSortOrders: [
      {
        sortField: 'createdOn',
        sortOrder: APISortOrder.DESC,
      },
    ],
  },
  loadOrganizationsPending: false,
  organizationsListLoaded: false,
  allOrganizationsLoaded: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
};

const builder = simplifyBuilder(organizationState, {});

const updateOrganizationFilters = builder.createReduxAction(
  (filters: APIOrganizationSearchFilter) => ({
    name: 'updateOrganizationFilters',
    updater: () => ({
      filters,
    }),
  }),
);

const resetOrganizationFilters = builder.createReduxAction(() => ({
  name: 'resetOrganizationFilters',
  updater: () => ({
    filters: organizationState.filters,
    pagination: organizationState.pagination,
  }),
}));

export const loadOrganizations = builder.createServerAction(() => ({
  name: 'loadOrganizations',
  url: organizationEndpoint(),
  body: (state: State) => ({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    ...state.organization.filters,
  }),
  method: httpMethod.post,
  onSuccess: (
    state: OrganizationState,
    payload: APIOrganizationSearchResult,
  ) => ({
    organizationsList: payload.result,
    organizationsListLoaded: true,
    allOrganizationsLoaded: payload.result.length < payload.pageSize,
    pagination: {
      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
    },
  }),
}));

export const loadMoreOrganizations = builder.createServerAction(() => ({
  name: 'loadMoreOrganizations',
  url: organizationEndpoint(),
  body: (state: State) => ({
    pageNumber: getPageNumberAfterLoadMore(
      state.organization.organizationsList,
      state.organization.pagination.pageSize,
    ),
    ...state.organization.filters,
  }),
  method: httpMethod.post,
  onSuccess: (
    state: OrganizationState,
    payload: APIOrganizationSearchResult,
  ) => ({
    organizationsList: [...(state.organizationsList || []), ...payload.result],
    organizationsListLoaded: true,
    allOrganizationsLoaded: payload.result.length < payload.pageSize,
    pagination: {
      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
    },
  }),
}));

export const loadOrganization = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadOrganization',
    url: organizationSingleEndpoint(organizationId),
    method: httpMethod.get,
    onSuccess: (state: OrganizationState, payload: APIOrganization) => ({
      organization: payload,
    }),
  }),
);

export const deleteOrganization = builder.createServerAction(
  (organizationId: string) => ({
    name: 'deleteOrganization',
    url: organizationSingleEndpoint(organizationId),
    method: httpMethod.delete,
    onSuccess: (state: OrganizationState) => ({
      organizationsList: state.organizationsList.filter(
        organization => organization.id !== organizationId,
      ),
    }),
  }),
);

export const updateOrganization = builder.createServerAction(
  (organizationId: string, model: APIOrganization) => ({
    name: 'updateOrganization',
    url: organizationSingleEndpoint(organizationId),
    method: httpMethod.post,
    body: model,
    onSuccess: (state: OrganizationState, result: APIOrganization) => ({
      organizationsList: state.organizationsList.map(organization =>
        organization.id === organizationId ? result : organization,
      ),
    }),
  }),
);

export const clearOrganization = builder.createReduxAction(() => ({
  name: 'clearOrganization',
  updater: () => ({
    organization: null,
  }),
}));

export const createOrganization = builder.createServerAction(
  (model: APIOrganization) => ({
    name: 'createOrganization',
    url: organizationEndpoint(),
    method: httpMethod.put,
    body: model,
    onSuccess: (state: OrganizationState, result: APIOrganization) => ({
      organizationsList: [result, ...state.organizationsList],
    }),
  }),
);

export const useOrganization = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          clearOrganization,
          resetOrganizationFilters,
          updateOrganizationFilters,
          createOrganization,
          updateOrganization,
          deleteOrganization,
          loadOrganization,
          loadOrganizations,
          loadMoreOrganizations,
        },
        dispatch,
      ),
    [dispatch],
  );
};

export const organizationReducer = builder.getReducers();
