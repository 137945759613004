import React, { useEffect, useRef } from 'react';

import { styled } from 'styled-components';

import { Input } from 'components';

import { ColumnFilteringProps } from '../types';

import { _ } from 'third-party';

const FilterWrapper = styled.div`
  padding: 0.1rem 0 0.3rem 0;

  & input {
    padding: 0.1rem !important;
  }
`;

export const StringFilter: React.FC<ColumnFilteringProps> = ({
  columnId,
  value,
  onFilterChange,
}) => {
  const filterValue = useRef<HTMLInputElement>(null);
  const onChange = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange?.(columnId.toString(), event.target.value);
  }, 500);

  useEffect(() => {
    if (filterValue.current) {
      filterValue.current.value = value || '';
    }
  }, [value]);

  return (
    <FilterWrapper>
      <Input inputRef={filterValue} onChange={onChange} />
    </FilterWrapper>
  );
};
