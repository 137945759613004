import { useMemo } from 'react';

import { State } from 'modules';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import { DEFAULT_PAGE_SIZE } from 'constants/common';
import { userListEndpoint, userSingleEndpoint } from 'constants/endpoints';

import { appSelectors } from 'selectors';

import { getPageNumberAfterLoadMore } from 'utils/helper';
import { httpMethod, simplifyBuilder } from 'utils/sra';

import {
  APISortOrder,
  APIUser,
  APIUserSearchFilter,
  APIUserSearchResult,
} from 'types/api';

export type UserState = {
  usersList: APIUser[];
  user: APIUser | null;
  loadUserPending: boolean;
  loadUsersPending: boolean;
  allUsersLoaded: boolean;
  usersListLoaded: boolean;
  filters: APIUserSearchFilter;
  pagination: {
    pageNumber: APIUserSearchResult['pageNumber'];
    pageSize: APIUserSearchResult['pageSize'];
  };
};

export const userState: UserState = {
  usersList: [],
  user: null,
  loadUserPending: false,
  filters: {
    searchSortOrders: [
      {
        sortField: 'Id',
        sortOrder: APISortOrder.DESC,
      },
    ],
  },
  loadUsersPending: false,
  usersListLoaded: false,
  allUsersLoaded: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
};

const builder = simplifyBuilder(userState, {});

const updateUserFilters = builder.createReduxAction(
  (filters: APIUserSearchFilter) => ({
    name: 'updateUserFilters',
    updater: () => ({
      filters,
    }),
  }),
);

export const loadUsers = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadUsers',
    url: userListEndpoint(organizationId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      ...state.user.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: UserState, payload: APIUserSearchResult) => ({
      usersList: payload.result,
      usersListLoaded: true,
      allUsersLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadMoreUsers = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadMoreUsers',
    url: userListEndpoint(organizationId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.user.usersList,
        state.user.pagination.pageSize,
      ),
      ...state.user.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: UserState, payload: APIUserSearchResult) => ({
      usersList: [...(state.usersList || []), ...payload.result],
      usersListLoaded: true,
      allUsersLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadUser = builder.createServerAction(
  (organizationId: string, userId: string) => ({
    name: 'loadUser',
    url: userSingleEndpoint(organizationId, userId),
    method: httpMethod.get,
    onSuccess: (state: UserState, payload: APIUser) => ({
      user: payload,
    }),
  }),
);

export const deleteUser = builder.createServerAction(
  (organizationId: string, userId: string) => ({
    name: 'deleteUser',
    url: userSingleEndpoint(organizationId, userId),
    method: httpMethod.delete,
    onSuccess: (state: UserState) => ({
      usersList: state.usersList.filter(user => user.id !== userId),
    }),
  }),
);

export const updateUser = builder.createServerAction(
  (organizationId: string, userId: string, model: APIUser) => ({
    name: 'updateUser',
    url: userSingleEndpoint(organizationId, userId),
    method: httpMethod.post,
    body: model,
    onSuccess: (state: UserState, result: APIUser) => ({
      usersList: state.usersList.map(user =>
        user.id === userId ? result : user,
      ),
    }),
  }),
);

export const createUser = builder.createServerAction(
  (organizationId: string, model: APIUser) => ({
    name: 'createUser',
    url: userListEndpoint(organizationId),
    method: httpMethod.put,
    body: { ...model },
    onSuccess: (state: UserState, result: APIUser) => ({
      usersList: [result, ...state.usersList],
    }),
  }),
);

export const clearUser = builder.createReduxAction(() => ({
  name: 'clearUser',
  updater: () => ({
    user: null,
  }),
}));

export const useUser = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(appSelectors.organizationId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          updateUserFilters,
          clearUser,
          createUser: createUser.bind(null, organizationId),
          updateUser: updateUser.bind(null, organizationId),
          deleteUser: deleteUser.bind(null, organizationId),
          loadUser: loadUser.bind(null, organizationId),
          loadUsers: loadUsers.bind(null, organizationId),
          loadMoreUsers: loadMoreUsers.bind(null, organizationId),
        },
        dispatch,
      ),
    [dispatch, organizationId],
  );
};

export const userReducer = builder.getReducers();
