import React, { useEffect, useState } from 'react';

import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
} from 'components';
import { useNotifications } from 'hooks';

import {
  styled,
  useForm,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { dashboardUrl, registerUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { FormField } from 'components/FormField/FormField';

import { emailField } from 'utils/validations';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  min-width: 25rem;
`;

enum FormFields {
  Email = 'email',
  Password = 'password',
}

const loginFormDefaultValues = {
  [FormFields.Email]: '',
  [FormFields.Password]: '',
};

type LoginFormData = typeof loginFormDefaultValues;

export const Login = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof loginFormDefaultValues>({
    defaultValues: loginFormDefaultValues,
  });

  const navigate = useNavigate();
  const { userLogIn } = useApp();
  const { error } = useNotifications();
  const userInfoPending = useSelector(appSelectors.userInfoPending);
  const user = useSelector(appSelectors.user);

  useEffect(() => {
    if (!userInfoPending && user) {
      navigate(dashboardUrl());
    }
  }, [navigate, user, userInfoPending]);

  const onSubmit = async ({ email, password }: LoginFormData) => {
    const result = await userLogIn(email, password);

    if (result.error) {
      error(t('login.invalidCredentials'));
      return;
    }

    navigate(dashboardUrl());
  };

  const onRegisterClick = () => {
    navigate(registerUrl());
  };

  return (
    <Box>
      <Box paddingBottom="1rem">
        <Typography variant="h2" textAlign="center">
          {t('login.header')}
        </Typography>
        <Typography variant="subtitle1" paddingTop="0.8rem">
          {t('login.subHeader')}
        </Typography>
      </Box>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          fieldName={FormFields.Email}
          fieldError={errors[FormFields.Email]}
          placeholder={t('common.form.email.placeholder')}
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
          rules={{
            validate: {
              invalidEmail: (value: string) =>
                emailField.validate(value) ||
                t('common.form.email.error.invalid'),
            },
          }}
        />
        <FormField
          type={showPassword ? 'text' : 'password'}
          fieldName={FormFields.Password}
          fieldError={errors[FormFields.Password]}
          placeholder={t('common.form.password.placeholder')}
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          paddingTop="1rem"
          gap="1.7rem"
        >
          <Button
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
          >
            {t('login.signIn')}
          </Button>
          <Divider>{t('login.continueWith')}</Divider>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            onClick={onRegisterClick}
          >
            <PersonOutlineRoundedIcon />
            {t('login.register')}
          </Button>
          <Typography variant="subtitle1" textAlign="center" maxWidth="25rem">
            {t('login.terms')}
          </Typography>
        </Box>
      </Form>
    </Box>
  );
};
