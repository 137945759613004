import React, { useMemo } from 'react';

import styled, { useTheme } from 'styled-components';

import {
  Box,
  Dialog,
  Grid,
  Loader,
  LoadingButton,
  Typography,
} from 'components';
import i18next from 'i18next';

const DialogControls = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

type DialogModalProps = {
  onClose: () => void;
  open: boolean;
  handleCancelClick?: () => void;
  actionPending?: boolean;
  handleActionClick: () => void;
  title?: string;
  description?: string;
  cancelButtonTitle?: string;
  actionButtonTitle?: string;
  actionButtonDisabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactElement;
  onCloseAnimationEnd?: (node: HTMLElement) => void;
};

export const DialogModal: React.FC<DialogModalProps> = ({
  title = i18next.t('common.form.deleteConfirm'),
  description,
  cancelButtonTitle = i18next.t('common.form.cancel'),
  actionButtonTitle = i18next.t('common.form.confirm'),
  onClose,
  open,
  handleCancelClick,
  actionPending,
  handleActionClick,
  onCloseAnimationEnd,
  actionButtonDisabled,
  children,
  isLoading,
}) => {
  const theme = useTheme();

  const transitionProps = useMemo(
    () => ({ onExited: onCloseAnimationEnd }),
    [onCloseAnimationEnd],
  );

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={transitionProps}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box padding="1rem">
          <Typography variant="h2" marginBottom="1rem">
            {title}
          </Typography>
          {description && (
            <Typography variant="h3" color={theme.custom.palette.gray}>
              {description}
            </Typography>
          )}
          {children}
          <DialogControls>
            <LoadingButton
              variant="outlined"
              color="error"
              disabled={actionPending}
              onClick={handleCancelClick || onClose}
            >
              {cancelButtonTitle}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              onClick={handleActionClick}
              loading={actionPending}
              disabled={actionButtonDisabled}
            >
              {actionButtonTitle}
            </LoadingButton>
          </DialogControls>
        </Box>
      )}
    </Dialog>
  );
};
