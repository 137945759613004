import React from 'react';

import { Box, PageHeader } from 'components';

import { useTranslation } from 'third-party';

export const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <Box minWidth="30rem">
      <PageHeader
        header={t('common.empty.header')}
        subHeader={t('common.empty.subHeader')}
      />
    </Box>
  );
};
