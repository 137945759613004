import React, { useEffect, useRef } from 'react';

import { Box, Typeahead } from 'components';
import { GroupBase, StylesConfig } from 'react-select';

import { ColumnFilteringProps } from 'components/Table/types';
import {
  Control,
  styles,
} from 'components/TypeaheadFormField/TypeaheadFormField';

import { TypeaheadOption } from 'types/app';

type Props = ColumnFilteringProps & { loadOptions: any };

const typeaheadStyles: StylesConfig<
  TypeaheadOption,
  boolean,
  GroupBase<TypeaheadOption>
> = {
  ...styles,
  indicatorsContainer: () => ({
    margin: 0,
    padding: 0,
  }),
  input: () => ({
    height: '1.4rem',
    marginLeft: '0.1rem',
    fontSize: '0.875rem',
    fontFamily: 'Inter',
    fontWeight: 400,
    border: 'none',
  }),
  valueContainer: () => ({
    ...styles.valueContainer,
    maxHeight: '1.35rem',
    overflow: 'hidden',
  }),
  multiValue: () => ({
    ...styles.multiValue,
  }),
  option: () => ({
    margin: 0,
    padding: 0,
  }),
  noOptionsMessage: baseStyles => ({
    ...baseStyles,
    margin: 0,
    padding: 0,
    height: '1.35rem',
    fontSize: '0.875rem',
    fontFamily: 'Inter',
    fontWeight: 400,
  }),
  loadingMessage: baseStyles => ({
    ...baseStyles,
    height: '1.35rem',
    margin: 0,
    padding: 0,
    fontSize: '0.875rem',
    fontFamily: 'Inter',
    fontWeight: 400,
  }),
  control: () => ({
    ...styles.control,
    outline: 0,
    borderWidth: '0 0 2px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between',
    justifyContent: 'center',
    maxHeight: '1.35rem',
    border: 'none',
  }),
};

export const TypeaheadFilter: React.FC<Props> = ({
  value,
  columnId,
  onFilterChange,
  ...props
}) => {
  const ref = useRef<any>(null);

  const handleChange = (event: TypeaheadOption | null) => {
    onFilterChange?.(columnId.toString(), event?.value ?? '');
  };

  useEffect(() => {
    if (!value) {
      ref.current.clearValue();
    }
  }, [value]);

  return (
    <Box maxHeight="1.6rem">
      <Typeahead
        isFlat
        ref={ref}
        styles={typeaheadStyles}
        placeholder=""
        components={{ Control }}
        onChange={handleChange}
        clearButtonStyle={{
          fontSize: '0.3rem',
          padding: '3px',
          height: '0.8rem',
          width: '0.8rem',
        }}
        {...props}
        // @ts-ignore TODO: find out the the right type
        hasMultiStyling={props.isMulti}
        hideDropdownIndicator
      />
    </Box>
  );
};
