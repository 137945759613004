import React from 'react';

import { Box, Popover } from 'components';

import { ModalCloseButton } from './ModalCloseButton';

export type Props = {
  height?: string;
  width?: string;
  onClose: () => void;
  children?: React.ReactNode;
};

export const ModalBox: React.FC<Props> = ({
  height,
  width,
  onClose,
  children,
}) => {
  return (
    <Popover
      anchorEl={document.body}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open={true}
      PaperProps={{ sx: { overflow: 'visible' } }}
    >
      <Box position="absolute" right="-0.2rem" top="-0.2rem">
        <ModalCloseButton onClose={onClose} />
      </Box>
      <Box
        height={height ?? '10rem'}
        width={width ?? '20rem'}
        overflow="scroll"
      >
        {children}
      </Box>
    </Popover>
  );
};
