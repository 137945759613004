import { useMemo } from 'react';

import { State } from 'modules';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import { DEFAULT_PAGE_SIZE } from 'constants/common';
import {
  payeeEditEndpoint,
  payeeEndpoint,
  payeeSingleEndpoint,
  userPayeeEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

import { getPageNumberAfterLoadMore } from 'utils/helper';
import { httpMethod, simplifyBuilder } from 'utils/sra';

import {
  APIPayee,
  APIPayeeSearchFilter,
  APIPayeeSearchResult,
  APISortOrder,
} from 'types/api';

export type PayeeState = {
  payeeList: APIPayee[];
  payee: APIPayee | null;
  loadPayeePending: boolean;
  loadPayeesPending: boolean;
  allPayeesLoaded: boolean;
  payeeListLoaded: boolean;
  filters: APIPayeeSearchFilter;
  pagination: {
    pageNumber: APIPayeeSearchResult['pageNumber'];
    pageSize: APIPayeeSearchResult['pageSize'];
  };
};

export const payeeState: PayeeState = {
  payeeList: [],
  payee: null,
  loadPayeePending: false,
  filters: {
    searchSortOrders: [
      {
        sortField: 'createdOn',
        sortOrder: APISortOrder.DESC,
      },
    ],
  },
  loadPayeesPending: false,
  payeeListLoaded: false,
  allPayeesLoaded: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
};

const builder = simplifyBuilder(payeeState, {});

const updatePayeeFilters = builder.createReduxAction(
  (filters: APIPayeeSearchFilter) => ({
    name: 'updatePayeeFilters',
    updater: () => ({
      filters,
    }),
  }),
);

export const loadPayees = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadPayees',
    url: organizationId ? payeeEndpoint(organizationId) : userPayeeEndpoint(),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      ...state.payee.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: PayeeState, payload: APIPayeeSearchResult) => ({
      payeeList: payload.result,
      payeeListLoaded: true,
      allPayeesLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadMorePayees = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadMorePayees',
    url: organizationId ? payeeEndpoint(organizationId) : userPayeeEndpoint(),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.payee.payeeList,
        state.payee.pagination.pageSize,
      ),
      ...state.payee.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: PayeeState, payload: APIPayeeSearchResult) => ({
      payeeList: [...(state.payeeList || []), ...payload.result],
      payeeListLoaded: true,
      allPayeesLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadPayee = builder.createServerAction(
  (organizationId: string, payeeId: string) => ({
    name: 'loadPayee',
    url: organizationId
      ? payeeSingleEndpoint(organizationId, payeeId)
      : payeeEditEndpoint(payeeId),
    method: httpMethod.get,
    onSuccess: (state: PayeeState, payload: APIPayee) => ({
      payee: payload,
    }),
  }),
);

export const deletePayee = builder.createServerAction(
  (organizationId: string, payeeId: string) => ({
    name: 'deletePayee',
    url: organizationId
      ? payeeSingleEndpoint(organizationId, payeeId)
      : payeeEditEndpoint(payeeId),
    method: httpMethod.delete,
    onSuccess: (state: PayeeState) => ({
      payeeList: state.payeeList.filter(payee => payee.id !== payeeId),
    }),
  }),
);

export const updatePayee = builder.createServerAction(
  (organizationId: string, payeeId: string, model: APIPayee) => ({
    name: 'updatePayee',
    url: organizationId
      ? payeeSingleEndpoint(organizationId, payeeId)
      : payeeEditEndpoint(payeeId),
    method: httpMethod.post,
    body: model,
    onSuccess: (state: PayeeState, result: APIPayee) => ({
      payeeList: state.payeeList.map(payee =>
        payee.id === payeeId ? result : payee,
      ),
    }),
  }),
);

export const createPayee = builder.createServerAction(
  (organizationId: string, model: APIPayee) => ({
    name: 'createPayee',
    url: organizationId ? payeeEndpoint(organizationId) : userPayeeEndpoint(),
    method: httpMethod.put,
    body: model,
    onSuccess: (state: PayeeState, result: APIPayee) => ({
      payeeList: [result, ...state.payeeList],
    }),
  }),
);

const resetPayeeFilters = builder.createReduxAction(() => ({
  name: 'resetPayeeFilters',
  updater: () => ({
    filters: payeeState.filters,
    pagination: payeeState.pagination,
  }),
}));

export const clearPayee = builder.createReduxAction(() => ({
  name: 'clearPayee',
  updater: () => ({
    payee: null,
  }),
}));

export const usePayee = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(appSelectors.organizationId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          updatePayeeFilters,
          resetPayeeFilters,
          clearPayee,
          loadPayee: loadPayee.bind(null, organizationId),
          createPayee: createPayee.bind(null, organizationId),
          updatePayee: updatePayee.bind(null, organizationId),
          deletePayee: deletePayee.bind(null, organizationId),
          loadPayees: loadPayees.bind(null, organizationId),
          loadMorePayees: loadMorePayees.bind(null, organizationId),
        },
        dispatch,
      ),
    [dispatch, organizationId],
  );
};

export const payeeReducer = builder.getReducers();
