import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Button,
  FormDateField,
  FormField,
  FormNumberField,
  Loader,
  PageHeader,
  TypeaheadFormField,
  Typography,
} from 'components';
import { useNotifications } from 'hooks';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';
import { usePayment } from 'modules/payment';

import { paymentSelectors } from 'selectors';

import {
  APIGenericList,
  APIPayee,
  APIPayment,
  APIPaymentRequest,
  APIPaymentStatus,
  APIResponse,
} from 'types/api';
import { TypeaheadOption } from 'types/app';

enum FormFields {
  Id = 'id',
  BankId = 'bankId',
  PayeeId = 'payeeId',
  Status = 'status',
  Amount = 'amount',
  CheckNumber = 'checkNumber',
  CheckDate = 'checkDate',
  Note = 'note',
  Memo = 'memo',
  Name = 'name',
  Company = 'company',
  NameOnCheck = 'nameOnCheck',
  ReferenceId = 'referenceId',
  Email = 'email',
  Phone = 'phone',
  Address = 'address',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  Kid = 'kId',
}

type FormData = {
  [FormFields.Id]: string;
  [FormFields.BankId]: {
    label: string | null | undefined;
    value: string | undefined;
  } | null;
  [FormFields.PayeeId]: {
    label: string | null | undefined;
    value: string | undefined;
  } | null;
  [FormFields.Amount]: string;
  [FormFields.Status]: APIPaymentStatus;
  [FormFields.CheckNumber]: string;
  [FormFields.CheckDate]: string | null;
  [FormFields.Note]: string;
  [FormFields.Memo]: string;
  [FormFields.Name]: string;
  [FormFields.Company]: string;
  [FormFields.NameOnCheck]: string;
  [FormFields.ReferenceId]: string;
  [FormFields.Email]: string;
  [FormFields.Phone]: string;
  [FormFields.Address]: string;
  [FormFields.City]: string;
  [FormFields.State]: string;
  [FormFields.Zip]: string;
  [FormFields.Kid]: string;
};

type CreateEditProps = {
  onModalClose: () => void;
  paymentId?: string | null;
};

export const PaymentCreateEditForm: React.FC<CreateEditProps> = ({
  paymentId,
  onModalClose,
}) => {
  const { loadPayment, clearPayment } = usePayment();

  const isEditMode = !!paymentId;
  const payment = useSelector(paymentSelectors.payment);

  useEffect(() => {
    if (paymentId) {
      loadPayment(paymentId);
    }
    return () => {
      clearPayment();
    };
  }, [paymentId, loadPayment, clearPayment]);

  if (isEditMode && !payment) {
    return <Loader />;
  }

  return (
    <CreateEditForm
      payment={payment}
      onModalClose={onModalClose}
      isEditMode={isEditMode}
    />
  );
};

type Props = {
  payment: APIPayment | null;
  onModalClose: () => void;
  isEditMode: boolean;
};

export const CreateEditForm: React.FC<Props> = ({
  payment,
  onModalClose,
  isEditMode,
}) => {
  const { error, success } = useNotifications();
  const { updatePayment, createPayment } = usePayment();
  const { loadBankTypeahead, loadPayeeTypeahead } = useCache();
  const { t } = useTranslation();
  const disabledEdit = isEditMode && payment?.status !== APIPaymentStatus.Draft;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Id]: payment?.id || '',
      [FormFields.BankId]: {
        label: payment?.bank?.bankName,
        value: payment?.bank?.id,
      },
      [FormFields.Amount]: payment?.amount?.toString() || '',
      [FormFields.CheckNumber]: payment?.checkNumber || '',
      [FormFields.CheckDate]: payment?.checkDate || null,
      [FormFields.Note]: payment?.note || '',
      [FormFields.PayeeId]: payment?.payee?.id
        ? {
            label: payment?.payee?.nameOnCheck || '',
            value: payment?.payee?.id || '',
          }
        : null,
      [FormFields.Memo]: payment?.memo || '',
      [FormFields.Name]: payment?.name || '',
      [FormFields.Company]: payment?.company || '',
      [FormFields.Status]: payment?.status || APIPaymentStatus.Created,
      [FormFields.NameOnCheck]: payment?.payee?.nameOnCheck || '',
      [FormFields.ReferenceId]: payment?.referenceId || '',
      [FormFields.Email]: payment?.payee?.email || '',
      [FormFields.Phone]: payment?.payee?.phone || '',
      [FormFields.Address]: payment?.payee?.address || '',
      [FormFields.City]: payment?.payee?.city || '',
      [FormFields.State]: payment?.payee?.state || '',
      [FormFields.Zip]: payment?.payee?.zip || '',
      [FormFields.Kid]: payment?.kId || '',
    },
  });

  const isFormPending = false;

  const handleCancel = () => {
    onModalClose();
  };

  const onSubmit = async (formData: FormData) => {
    if (isEditMode && payment) {
      const result = await updatePayment(payment?.id, {
        ...formData,
        bankId: formData.bankId?.value,
        payeeId: formData.payeeId?.value,
      } as any as APIPaymentRequest);
      if (result.error) {
        error(result.payload.body?.title || t('paymentForm.edit.error'));
      } else {
        success(t('paymentForm.edit.sucscefull'));
        onModalClose();
      }
      return;
    }

    // create
    const result = await createPayment({
      ...formData,
      bankId: formData.bankId?.value,
      payeeId: formData.payeeId?.value,
    } as any as APIPaymentRequest);
    if (result.error) {
      error(result.payload.body?.title || t('paymentForm.create.error'));
    } else {
      success(t('paymentForm.create.sucscefull'));
      onModalClose();
    }
  };

  const loadBank = async (value: string) => {
    const result: APIResponse<{ name: string; id: string }[]> =
      await loadBankTypeahead(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it.name,
        value: it.id,
      }));
    }
    return {
      options,
    };
  };

  const loadPayees = async (value: string) => {
    const result: APIResponse<APIGenericList<APIPayee>> =
      await loadPayeeTypeahead(value);

    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload?.result.map(it => {
        const data = [it.city, it.state, it.email].filter(Boolean).join(', ');

        return {
          label: `${it.nameOnCheck} (${data})`,
          value: it.id,
          ...it,
        };
      });
    }

    return {
      options,
    };
  };

  const onBankChange = useCallback(
    (option: TypeaheadOption | null) => {
      setValue(FormFields.BankId, option || { label: null, value: undefined }, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onPayeeChange = useCallback(
    (option: APIPayee | null) => {
      setValue(FormFields.PayeeId, option as any, {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (option) {
        setValue(FormFields.NameOnCheck, option.nameOnCheck || '');
        setValue(FormFields.Email, option.email || '');
        setValue(FormFields.Phone, option.phone || '');
        setValue(FormFields.Address, option.address || '');
        setValue(FormFields.City, option.city || '');
        setValue(FormFields.State, option.state || '');
        setValue(FormFields.City, option.state || '');
        setValue(FormFields.Zip, option.zip || '');
      }
    },
    [setValue],
  );

  return (
    <Box padding="1.5rem">
      <form>
        <PageHeader
          header={
            isEditMode
              ? t('paymentForm.edit.header')
              : t('paymentForm.create.header')
          }
          subHeader={
            isEditMode
              ? t('paymentForm.edit.subHeader')
              : t('paymentForm.create.subHeader')
          }
        >
          <Box display="flex" columnGap="1rem">
            <Button
              type="submit"
              variant="outlined"
              onClick={handleSubmit(data =>
                onSubmit({ ...data, status: APIPaymentStatus.Created }),
              )}
              size="medium"
              color="primary"
            >
              {t('common.form.save')}
            </Button>
            {(!isEditMode || payment?.status == APIPaymentStatus.Draft) && (
              <Button
                type="submit"
                variant="outlined"
                onClick={handleSubmit(data =>
                  onSubmit({ ...data, status: APIPaymentStatus.Draft }),
                )}
                size="medium"
                color="secondary"
              >
                {t('common.form.saveAsDraft')}
              </Button>
            )}
            <Button
              type="button"
              variant="outlined"
              onClick={handleCancel}
              size="medium"
              color="error"
            >
              {t('common.form.cancel')}
            </Button>
          </Box>
        </PageHeader>

        <FormField
          fieldName={FormFields.BankId}
          label={t('paymentList.column.bank')}
          fieldError={errors[FormFields.BankId] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          render={() => (
            <TypeaheadFormField
              searchIcon
              placeholder={
                t('common.form.typeToSearchValueFor') +
                t('paymentList.column.bank')
              }
              defaultValue={
                payment
                  ? {
                      label: payment?.bank?.bankName ?? '',
                      value: payment?.bank?.id ?? '',
                    }
                  : null
              }
              loadOptions={loadBank}
              onChange={onBankChange}
              isDisabled={isFormPending || disabledEdit}
            />
          )}
        />
        <FormField
          fieldName={FormFields.PayeeId}
          label={t('paymentList.column.payee')}
          fieldError={errors[FormFields.PayeeId] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          isRequired={false}
          render={() => (
            <TypeaheadFormField
              searchIcon
              placeholder={
                t('common.form.typeToSearchValueFor') +
                t('paymentList.column.payee')
              }
              defaultValue={
                payment
                  ? {
                      label: payment?.payee?.nameOnCheck ?? '',
                      value: payment?.payee?.id ?? '',
                    }
                  : null
              }
              loadOptions={loadPayees}
              onChange={onPayeeChange}
              isDisabled={isFormPending || disabledEdit}
            />
          )}
        />
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            display: '-webkit-box',
            fontSize: '0.7rem',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {t('paymentList.column.payee.helpText')}
        </Typography>
        <FormNumberField
          fieldName={FormFields.Amount}
          precision={2}
          fieldError={errors[FormFields.Amount] as FieldError}
          label={t('paymentList.column.amount')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.amount')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={10}
          isRequired
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.CheckNumber}
          fieldError={errors[FormFields.CheckNumber] as FieldError}
          label={t('paymentList.column.checkNumber')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.checkNumber')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending || disabledEdit}
        />
        <FormDateField
          isRequired
          showError
          fieldName={FormFields.CheckDate}
          label={t('paymentList.column.checkDate')}
          fieldError={errors[FormFields.CheckDate] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.checkDate')
          }
          control={control}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.Note}
          fieldError={errors[FormFields.Note] as FieldError}
          label={t('paymentList.column.note')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.note')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={255}
          isRequired={false}
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.Memo}
          fieldError={errors[FormFields.Memo] as FieldError}
          label={t('paymentList.column.memo')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.memo')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={255}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.Name}
          fieldError={errors[FormFields.Name] as FieldError}
          label={t('paymentList.column.name')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.name')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.Company}
          fieldError={errors[FormFields.Company] as FieldError}
          label={t('paymentList.column.company')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.company')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.NameOnCheck}
          fieldError={errors[FormFields.NameOnCheck] as FieldError}
          label={t('paymentList.column.nameOnCheck')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.nameOnCheck')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.ReferenceId}
          fieldError={errors[FormFields.ReferenceId] as FieldError}
          label={t('paymentList.column.referenceId')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.referenceId')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.Email}
          fieldError={errors[FormFields.Email] as FieldError}
          label={t('paymentList.column.email')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.email')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.Phone}
          fieldError={errors[FormFields.Phone] as FieldError}
          label={t('paymentList.column.phone')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.phone')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={25}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.City}
          fieldError={errors[FormFields.City] as FieldError}
          label={t('paymentList.column.city')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.city')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.Address}
          fieldError={errors[FormFields.Address] as FieldError}
          label={t('paymentList.column.address')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.address')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={255}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.State}
          fieldError={errors[FormFields.State] as FieldError}
          label={t('paymentList.column.state')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.state')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={2}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.Zip}
          fieldError={errors[FormFields.Zip] as FieldError}
          label={t('paymentList.column.state')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.zip')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={25}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
        <FormField
          fieldName={FormFields.Kid}
          fieldError={errors[FormFields.Kid] as FieldError}
          label={t('paymentList.column.kId')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.kId')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired={false}
          isDisabled={isFormPending || disabledEdit}
        />
      </form>
    </Box>
  );
};
