import React, { useEffect } from 'react';

import { Typography } from 'components';
import Logo from 'images/logo-white.svg';

import {
  RouterOutlet,
  styled,
  useNavigate,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { dashboardUrl } from 'constants/url';

import { appSelectors } from 'selectors';

import { visibleForTablet } from 'styles/common';

const LoginContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 40%;
  background-color: ${props => props.theme.custom.palette.primary900};

  ${visibleForTablet()}
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LoginView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();
  const userInfoPending = useSelector(appSelectors.userInfoPending);
  const user = useSelector(appSelectors.user);

  useEffect(() => {
    if (!userInfoPending && user) {
      navigate(dashboardUrl());
    }
  }, [navigate, user, userInfoPending]);

  return (
    <LoginContainer>
      <LeftPanel>
        <Typography
          variant="h3"
          margin="2rem"
          color={theme.custom.palette.whiteText}
        >
          <img src={Logo} height="35px" />
        </Typography>
        <Typography
          variant="subtitle1"
          margin="2rem"
          color={theme.custom.palette.whiteText}
        >
          {t('common.slogan')}
        </Typography>
      </LeftPanel>
      <RightContainer>
        <RouterOutlet />
      </RightContainer>
    </LoginContainer>
  );
};
