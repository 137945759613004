import { useMemo } from 'react';

import { useDispatch, useSelector } from 'third-party';

import {
  bankTypeaheadEndpoint,
  organizationTypeaheadEndpoint,
  payeeTypeaheadEndpoint,
  userTypeaheadEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

import { bindActionCreators, httpMethod, simplifyBuilder } from 'utils/sra';

import { APIGuidTypeaheadRequestModel } from 'types/swaggerApi';

export type CacheState = object;

export const cacheState: CacheState = {};

const builder = simplifyBuilder(cacheState, {});

const loadBankTypeahead = builder.createServerAction(
  (
    organizationId: string,
    query: string,
    body: Partial<APIGuidTypeaheadRequestModel> = {},
  ) => ({
    name: 'loadBankTypeahead',
    url: bankTypeaheadEndpoint(organizationId),
    body: {
      query: query,
      ...body,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const organizationTypeahead = builder.createServerAction(
  (query: string, body: Partial<APIGuidTypeaheadRequestModel> = {}) => ({
    name: 'loadOrganizationTypeahead',
    url: organizationTypeaheadEndpoint(),
    body: {
      query: query,
      ...body,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const loadUserTypeahead = builder.createServerAction(
  (
    organizationId: string,
    query: string,
    body: Partial<APIGuidTypeaheadRequestModel> = {},
  ) => ({
    name: 'loadUserTypeahead',
    url: userTypeaheadEndpoint(organizationId),
    body: {
      query: query,
      ...body,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const loadPayeeTypeahead = builder.createServerAction(
  (
    organizationId: string,
    query: string,
    body: Partial<APIGuidTypeaheadRequestModel> = {},
  ) => ({
    name: 'loadPayeeTypeahead',
    url: payeeTypeaheadEndpoint(organizationId),
    body: {
      query: query,
      count: 10,
      ...body,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

export const useCache = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(appSelectors.organizationId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadPayeeTypeahead: loadPayeeTypeahead.bind(null, organizationId),
          loadUserTypeahead: loadUserTypeahead.bind(null, organizationId),
          loadBankTypeahead: loadBankTypeahead.bind(null, organizationId),
          organizationTypeahead,
        },
        dispatch,
      ),
    [dispatch, organizationId],
  );
};

export const cacheReducer = builder.getReducers();
