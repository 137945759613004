import React, { useEffect } from 'react';

import { Box, Button, FormField, Loader, PageHeader } from 'components';
import { useNotifications } from 'hooks';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useBank } from 'modules/bank';

import { bankSelectors } from 'selectors';

import { APIBank } from 'types/api';

enum FormFields {
  Routing = 'routing',
  Account = 'account',
  AccountName = 'accountName',
  BankName = 'bankName',
  CompanyName = 'companyName',
  Address = 'address',
  City = 'city',
  State = 'state',
  Zip = 'zip',
}

type FormData = {
  [FormFields.Routing]: string;
  [FormFields.Account]: string;
  [FormFields.AccountName]: string;
  [FormFields.BankName]: string;
  [FormFields.CompanyName]: string;
  [FormFields.Address]: string;
  [FormFields.City]: string;
  [FormFields.State]: string;
  [FormFields.Zip]: string;
};

type CreateEditProps = {
  onModalClose: () => void;
  bankId?: string | null;
};

export const BankCreateEditForm: React.FC<CreateEditProps> = ({
  bankId,
  onModalClose,
}) => {
  const { loadBank, clearBank } = useBank();

  const isEditMode = !!bankId;

  const bank = useSelector(bankSelectors.bank);

  useEffect(() => {
    if (bankId) {
      loadBank(bankId);
    }
    return () => {
      clearBank();
    };
  }, [bankId, clearBank, loadBank]);

  if (isEditMode && !bank) {
    return <Loader />;
  }

  return (
    <CreateEditForm
      bank={bank}
      onModalClose={onModalClose}
      isEditMode={isEditMode}
    />
  );
};

type Props = {
  bank: APIBank | null;
  onModalClose: () => void;
  isEditMode: boolean;
};

export const CreateEditForm: React.FC<Props> = ({
  bank,
  isEditMode,
  onModalClose,
}) => {
  const { error, success } = useNotifications();
  const { updateBank, createBank } = useBank();

  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Routing]: bank?.routing || '',
      [FormFields.Account]: bank?.account || '',
      [FormFields.AccountName]: bank?.accountName || '',
      [FormFields.BankName]: bank?.bankName || '',
      [FormFields.CompanyName]: bank?.companyName || '',
      [FormFields.Address]: bank?.address || '',
      [FormFields.City]: bank?.city || '',
      [FormFields.State]: bank?.state || '',
      [FormFields.Zip]: bank?.zip || '',
    },
  });

  const isFormPending = false;

  const handleCancel = () => {
    onModalClose();
  };

  const onSubmit = async (formData: FormData) => {
    if (isEditMode && bank) {
      const result = await updateBank(bank?.id, formData as APIBank);
      if (result.error) {
        error(result.payload.body?.title || t('bankForm.edit.error'));
      } else {
        success(t('bankForm.edit.sucscefull'));
        onModalClose();
      }
      return;
    }

    // create
    const result = await createBank(formData as APIBank);
    if (result.error) {
      error(result.payload.body?.title || t('bankForm.create.error'));
    } else {
      success(t('bankForm.create.sucscefull'));
      onModalClose();
    }
  };

  return (
    <Box padding="1.5rem">
      <form>
        <PageHeader
          header={
            isEditMode ? t('bankForm.edit.header') : t('bankForm.create.header')
          }
          subHeader={
            isEditMode
              ? t('bankForm.edit.subHeader')
              : t('bankForm.create.subHeader')
          }
        >
          <Box display="flex" columnGap="1rem">
            <Button
              type="submit"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
              size="medium"
              color="primary"
            >
              {t('common.form.save')}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={handleCancel}
              size="medium"
              color="error"
            >
              {t('common.form.cancel')}
            </Button>
          </Box>
        </PageHeader>
        <FormField
          fieldName={FormFields.Routing}
          fieldError={errors[FormFields.Routing] as FieldError}
          label={t('bankList.column.routing')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.routing')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={22}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.Account}
          fieldError={errors[FormFields.Account] as FieldError}
          label={t('bankList.column.account')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.account')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={22}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.AccountName}
          fieldError={errors[FormFields.AccountName] as FieldError}
          label={t('bankList.column.accountName')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.accountName')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.BankName}
          fieldError={errors[FormFields.BankName] as FieldError}
          label={t('bankList.column.bankName')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.bankName')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.CompanyName}
          fieldError={errors[FormFields.CompanyName] as FieldError}
          label={t('bankList.column.companyName')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.companyName')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.Address}
          fieldError={errors[FormFields.Address] as FieldError}
          label={t('bankList.column.address')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.address')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={255}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.City}
          fieldError={errors[FormFields.City] as FieldError}
          label={t('bankList.column.city')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.city')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={100}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.State}
          fieldError={errors[FormFields.State] as FieldError}
          label={t('bankList.column.state')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.state')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={2}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={FormFields.Zip}
          fieldError={errors[FormFields.Zip] as FieldError}
          label={t('bankList.column.zip')}
          placeholder={
            t('common.form.enterValueFor') + t('bankList.column.zip')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={20}
          isRequired
          isDisabled={isFormPending}
        />
      </form>
    </Box>
  );
};
