export const SUPPORT_EMAIL = 'support@EasyCheck.org';

export const INFINITE_SCROLL_BOTTOM_OFFSET_PX = 300;
export const INFINITE_SCROLL_DEBOUNCE_TIME_MS = 3000;
export const TOOLTIP_APPEAR_DELAY = 1000;

export const REFRESH_TOKEN_IN_MINUTES = 10;
export const DEFAULT_PAGE_SIZE = 20;
export const USER_INPUT_DEBOUNCE_TIME_MS = 500;

export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
