export const emailRegexp = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
);

export const emailField = {
  maxLength: 254,
  validate(value: string) {
    return emailRegexp.test(value) && value.length <= this.maxLength;
  },
};

export const passwordField = {
  minLength: 8,
  maxLength: 64,
};
