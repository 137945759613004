import { AppState, appReducer } from './app';
import { BankState, bankReducer } from './bank';
import { CacheState, cacheReducer } from './cache';
import { OrganizationState, organizationReducer } from './organization';
import { PayeeState, payeeReducer } from './payee';
import { PaymentState, paymentReducer } from './payment';
import { UserState, userReducer } from './user';

import { applyMiddleware, createStore } from 'third-party';

import { getUserToken } from 'utils/auth';
import { combineReducers, compose, middlewareBuilder } from 'utils/sra';

export type State = {
  app: AppState;
  bank: BankState;
  organization: OrganizationState;
  user: UserState;
  cache: CacheState;
  payment: PaymentState;
  payee: PayeeState;
};

// custom middleware for web requests
const middleWare = [
  middlewareBuilder({
    httpRequestHandler: async (url: string, options?: Partial<RequestInit>) => {
      return await fetch(url, {
        ...(options?.body instanceof FormData
          ? {
              ...options,
              headers: new Headers({
                Authorization: getUserToken(),
                ...options?.headers,
              }),
            }
          : {
              ...options,
              headers: new Headers({
                'Content-Type': 'application/json',
                'client-os': 'web',
                accept: 'application/json',
                Authorization: getUserToken(),
                ...options?.headers,
              }),
            }),
      });
    },
  }),
];
// added support for redux-dev tool
export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// create store for redux
export const combinedReducer = combineReducers({
  app: appReducer,
  bank: bankReducer,
  user: userReducer,
  cache: cacheReducer,
  organization: organizationReducer,
  payment: paymentReducer,
  payee: payeeReducer,
});

export const store = createStore(
  combinedReducer,
  composeEnhancers(applyMiddleware(...middleWare)),
);
