import React from 'react';

import styled from 'styled-components';

import { Button } from 'components';

const StyledPopoverCloseButton = styled(Button)`
  position: absolute;
  top: -1rem;
  right: -0.5rem;
  z-index: 11 !important;
  float: right;
  background-color: white !important;
  min-width: 2rem !important;
  width: 2rem;
  height: 2rem;
  border-radius: 1.5rem !important;
  border: 2px solid ${props => props.theme.custom.palette.primary500};
  z-index: 3;
  color: ${props => props.theme.custom.palette.truegray800};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    border: 1px solid ${props => props.theme.custom.palette.primary900};
    background-color: white;
  }
`;

type ModalCloseButtonProps = {
  className?: string;
  onClose: () => void;
  isDisabled?: boolean;
};

export const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({
  className,
  onClose,
  isDisabled,
}) => {
  return (
    <StyledPopoverCloseButton
      className={className}
      onClick={onClose}
      variant="outlined"
      color="primary"
      disabled={isDisabled}
    >
      ✕
    </StyledPopoverCloseButton>
  );
};
