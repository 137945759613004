import React from 'react';

import { FormField, FormFieldProps } from 'components/FormField/FormField';

type Props = FormFieldProps & {
  precision?: number;
  fractionalDelimiter?: ',' | '.';
  mapToFractionalDelimiter?: ',' | '.';
  maxValue?: number;
  minValue?: number;
};

export const FormNumberField: React.FC<Props> = ({
  precision = 0,
  fractionalDelimiter = '.',
  mapToFractionalDelimiter = ',',
  maxLength,
  maxValue,
  minValue,
  ...props
}) => {
  return (
    <FormField
      mask={Number}
      maskOptions={{
        scale: precision,
        radix: fractionalDelimiter,
        mapToRadix: [mapToFractionalDelimiter],
        max: maxValue,
        min: minValue,
      }}
      inputProps={{
        maxLength,
        ...props.inputProps,
      }}
      {...props}
    />
  );
};
